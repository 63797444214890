import {mdiArrowRight, mdiMinus, mdiPlus} from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import CompactButton from 'Components/Buttons/CompactButton';
import {Field} from 'formik';
import RiskDropdown from 'Pages/Jobs/RiskDropdown';
import React, {useLayoutEffect, useRef, useState} from 'react';

const HazardField = ({hazards, suggestions, index, setFieldValue}) => {
  const [showAddRiskControlInput, setShowAddRiskControlInput] = useState(false);
  const inputField = useRef();

  const isRequired = () => {
    if (hazards[index]?.description){
      return hazards[index]?.description !== undefined
    }
    return false
  }

  const onInput = event => {
    const hazard = suggestions?.find(hazard => hazard.description === event.target.value);

    if (hazard) {
      hazards[index] = hazard;
    } else {
      hazards[index] = {
        hazard_controls: [],
        description: event.target.value,
        initial_risk_rating: '',
        residual_risk_rating: '',
      };
    }

    setFieldValue('hazards', hazards);
  };

  useLayoutEffect(() => {
    showAddRiskControlInput && inputField.current.focus();
  }, [showAddRiskControlInput]);

  const onKeyDown = e => {
    if (e.charCode === 13 || e.keyCode === 13) {
      addRiskControl();
    }
  };

  const addRiskControl = () => {
    const value = inputField.current.value;
    if (value.length) {
      if (hazards[index].hazard_controls) {
        typeof hazards[index].hazard_controls === 'undefined' && (hazards[index].hazard_controls = []);

        hazards[index].hazard_controls.push(value);
      } else {
        typeof hazards[index].hazard_controls === 'undefined' && (hazards[index].hazard_controls = []);

        hazards[index].hazard_controls.push(value);
      }
    }

    setFieldValue('hazards', hazards);
    inputField.current.value = '';
    setShowAddRiskControlInput(false);
  };

  const delRiskControl = i => {
    if (hazards[index].hazard_controls) {
      hazards[index].hazard_controls.splice(i, 1);
      setFieldValue('hazards', hazards);
      setShowAddRiskControlInput(false);
    } else {
      hazards[index].hazard_controls.splice(i, 1);
      setFieldValue('hazards', hazards);
      setShowAddRiskControlInput(false);
    }
  };

  const hazardControlItem = (hazardControl, index) => (
    <li key={index} className="text-sm leading-5 font-medium text-gray-800 cursor-pointer select-none flex justify-between items-center">
      <div>{hazardControl}</div>
      <CompactButton className="border border-gray-300" onClick={() => delRiskControl(index)}>
        <Icon path={mdiMinus} size={.5}/>
      </CompactButton>
    </li>
  );

  return (
    <div className="bg-white space-y-2 p-4 rounded">
      <Field
        placeholder="Add new Hazard"
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        name={`hazards.${index}.description`}
        list="suggestions-hazards"
        onInput={onInput}
      />

      <label className="space-y-2 space-x-2 flex">
        <div className="text-gray-800 text-sm flex justify-between items-center">
          Initial Risk Rating
        </div>
        {isRequired() && <span className="text-red-500">*</span>}
      </label>
      {/*risk_rating only appears on values that already exist in the Hazard register*/}
      <RiskDropdown name={hazards[index].initial_risk_rating ? `hazards.${index}.initial_risk_rating` : `hazards.${index}.risk_rating`}/>

      <label className="space-y-2 block">
        <div className="text-gray-800 text-sm flex justify-between items-center">
          Risk Controls
          <CompactButton className="border border-gray-300" onClick={() => setShowAddRiskControlInput(true)}>
            <Icon path={mdiPlus} size={.5}/>
          </CompactButton>
        </div>
        <ul className="space-y-2 list-disc list-outside pl-5">
          {hazards[index].hazard_controls ?
            hazards[index].hazard_controls.map((hazardControl, index) => (
              hazardControlItem(hazardControl, index)
            ))
            :
            hazards[index].hazard_controls.map((hazardControl, index) => (
              hazardControlItem(hazardControl, index)
            ))
          }
          <li
            key={index}
            className={cls(
              'text-sm leading-5 font-medium text-gray-800 cursor-pointer select-none flex justify-between items-center',
              !showAddRiskControlInput && 'hidden')
            }
          >
            <input
              ref={inputField}
              className="h-6 pl-4 appearance-none block px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              placeholder="New risk control"
              onKeyDown={onKeyDown}
            />
            <CompactButton className="border border-gray-300" onClick={addRiskControl}><Icon path={mdiArrowRight} size={.5}/></CompactButton>
          </li>
        </ul>
      </label>

      {typeof hazards[index].residual_risk_rating !== 'undefined' && (
        <>
          <label className="space-y-2 space-x-2 flex">
            <div className="text-gray-800 text-sm flex justify-between items-center">
              Residual Risk Rating
            </div>
            {isRequired() && <span className="text-red-500">*</span>}
          </label>
          <RiskDropdown name={`hazards.${index}.residual_risk_rating`}/>
        </>
      )}
    </div>
  );
};

export default HazardField;