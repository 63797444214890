import cls from 'classnames';
import React, {forwardRef} from 'react';
import {Link} from 'react-router-dom';
import route from 'Support/route';

const WarningOfDataLimit = ({className = ''}) => {

  return (
    <div
      className={cls('bg-white -mx-4 p-4 rounded-md shadow-lg mt-4', className,)}
    >
        <p>Your data is limited by a period of time. To change this go to your <Link to={route('settings')} className='text-primary'>Settings</Link> </p>
    </div>
  );
};

export default WarningOfDataLimit;
