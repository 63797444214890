import cls from 'classnames';
import React, {forwardRef} from 'react';

const PrimaryButton = ({children, className = '', as = 'button', ...props}, ref) => {
  const Element = as;

  if(!className.match(/(?:^|\s)h-\d+(?:$|\s)/)) {
    className += ' h-11';
  }

  return (
    <Element
      ref={ref}
      className={cls(
        'bg-primary inline-flex items-center justify-center text-white transition ease-in-out duration-150',
        'text-base px-6 rounded',
        'focus:outline-none hover:opacity-90',
        'disabled:cursor-default disabled:opacity-50', className,
      )}
      {...props}
    >
      {children}
    </Element>
  );
};

export default forwardRef(PrimaryButton);
