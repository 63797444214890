import {mdiArrowLeft} from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import FormWrapper from 'Components/Forms/FormWrapper';
import ViewContent from 'Components/Forms/ViewContent';
import ViewLabel from 'Components/Forms/ViewLabel';
import {format, parseJSON} from 'date-fns';
import {Form, Formik} from 'formik';
import FormField from 'Pages/Reports/FormField';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';
import * as Yup from 'yup';

const VisitorLog = () => {
  const {jobId, id} = useParams();

  const isOnline = useOnlineStatus();
  const [currentVisitorLog, setCurrentVisitorLog] = useState();
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [currentJob, setCurrentJob] = useState([]);
  const [formikProps, setFormikProps] = useState();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Please enter a name'),
  });

  const onSubmit = useFormikSubmit(async values => {
  });

  const sync = () => {
    const getHazards = async () => await api.fetchHazards();
    getHazards().then(async hazards => {
      await valueStore.set(`hazards`, hazards);
    });
  };

  useEffect(async () => {
    const tempJobs = await valueStore.getArray('jobs');
    setJobs(tempJobs);
    if (isOnline) {
      sync();
    }
  }, []);


  usePageTitle(`View Visitor Log`);

  useEffect(async () => {
    setCurrentJob(jobs?.find(job => job.id === parseInt(jobId)));
  }, [JSON.stringify(jobs)]);

  useEffect(async () => {
    if (!currentJob || !currentJob.view_logs || currentJob.view_logs.length === 0) return;
    const visitorLog = currentJob.view_logs?.find(visitorLog => visitorLog.id === parseInt(id));
    setCurrentVisitorLog(visitorLog);
    if (visitorLog?.job_id) {
      setCurrentJob(jobs?.find(job => job.id === visitorLog.job_id));
    }
  }, [currentJob]);

  useEffect(async () => {
    if (currentVisitorLog?.id) {
      setFormikProps({
        initialValues: {
          id: currentVisitorLog.id,
          name: currentVisitorLog.name,
          email: currentVisitorLog.email,
          phone: currentVisitorLog.phone,
          business: currentVisitorLog.business,
          location: currentVisitorLog.location,
          form: currentVisitorLog.form,
        },
      });
      setLoading(false);
    }
  }, [JSON.stringify(currentVisitorLog)]);

  return (
    <div className="m-4">
      <div className="flex justify-between">
        {currentVisitorLog?.job_id && <SecondaryButton as={Link} to={route('visitor-logs', {id: currentVisitorLog?.job_id})} className="mb-12 py-2">
          <Icon path={mdiArrowLeft} size={1} className="mr-1"/> Back
        </SecondaryButton>}
      </div>
      {!loading &&
        <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({values, setFieldValue, errors}) => (
            <Form>
              <FormWrapper className="bg-white rounded-lg shadow-md">
                <div className="space-y-4">
                  <div className="space-y-8">
                    <h3 className="font-bold text-lg text-gray-900">{values?.name}</h3>
                    <hr/>
                    <FieldWrapper>
                      <ViewLabel text="Phone"/>
                      <ViewContent text={values?.phone}/>
                    </FieldWrapper>
                    <FieldWrapper>
                      <ViewLabel text="Email"/>
                      <ViewContent text={values?.email}/>
                    </FieldWrapper>
                    <FieldWrapper>
                      <ViewLabel text="Date"/>
                      <ViewContent
                        text={values?.created_at === 'now' || !values?.created_at ? format(Date.now(), 'yyyy/MM/dd') : format(parseJSON(values?.created_at), 'yyyy/MM/dd')}/>
                    </FieldWrapper>
                    <FieldWrapper>
                      <ViewLabel text="Time"/>
                      <ViewContent
                        text={values?.created_at === 'now' || !values?.created_at ? format(Date.now(), 'hh:mm aaa') : format(parseJSON(values?.created_at), 'hh:mm aaa')}/>
                    </FieldWrapper>

                    <FieldWrapper>
                      <ViewLabel text="Location"/>
                      <ViewContent text={values?.location}/>
                    </FieldWrapper>
                    <FieldWrapper>
                      <ViewLabel text="Business"/>
                      <ViewContent text={values?.business}/>
                    </FieldWrapper>
                    {values?.form?.map((field, index) => (
                      <FormField key={index} index={index} values={values.form} setFieldValue={setFieldValue} induction={true}/>
                    ))}
                  </div>
                </div>
              </FormWrapper>
            </Form>
          )}
        </Formik>}
    </div>
  );
};

export default VisitorLog;
