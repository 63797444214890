import cls from 'classnames';
import React, {forwardRef, useEffect, useState} from 'react';
import api from 'Services/api';

const MainAppButton = ({children, className = '', url, ...props}, ref) => {

  const link = window.apiConfig[window.location.origin] + url;
  const [disabled, setDisabled] = useState(false);
  const [oneTimeToken, setOneTimeToken] = useState();

  const onClick = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setOneTimeToken(await api.fetchOneTimeToken());
  };

  useEffect(() => {
    if (oneTimeToken) {
      setDisabled(false);
      window.location.href = link+'?one_time_token='+oneTimeToken;
    }
  }, [oneTimeToken]);


  if (!className.includes('h-')) {
    className += ' h-11';
  }


  return (
    <button
       onClick={onClick}
       disabled={disabled}
       className={className} {...props}>
      {children}
    </button>
  );
};

export default forwardRef(MainAppButton);
