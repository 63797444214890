import {mdiArrowLeft, mdiChevronRight} from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import FormWrapper from 'Components/Forms/FormWrapper';
import TextField from 'Components/Forms/TextField';
import SyncSpinner from 'Components/SyncSpinner';
import {Form, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {geocodeByLatLng} from 'react-google-places-autocomplete';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import useOrganization from 'Support/hooks/useOrganization';
import usePageTitle from 'Support/hooks/usePageTitle';
import useUser from 'Support/hooks/useUser';
import route from 'Support/route';
import {v4 as uuidv4} from 'uuid';
import * as Yup from 'yup';

const AddJob = () => {
  const history = useHistory();

  const {organization_id} = useOrganization();
  const [jobs, setJobs] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  useEffect(async () => {
    setJobs(await valueStore.getArray(`jobs`) || []);
  }, []);

  usePageTitle(`Add Job`);

  const formikProps = {
    initialValues: {
      name: '',
      location: '',
      organization_id: organization_id
    },
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Please enter a name'),
    location: Yup.string()
      .required('Please provide a location'),
  });

  const onSubmit = useFormikSubmit(async values => {
    setSubmitting(true);

    await valueStore.set(`jobs`, [...jobs, {
      ...values,
      temp_id: uuidv4(),
      new: true,
      organization_id: organization_id,
    }]);

    history.push(route('jobs'));
  });

  return (
    <div className="m-4">
      <SyncSpinner loading={submitting}/>

      <SecondaryButton as={Link} to={route('jobs')} className="w-full mb-12 py-2">
        <Icon path={mdiArrowLeft} size={1} className="mr-1"/> Cancel
      </SecondaryButton>

      <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({setFieldValue, errors}) => {

          useEffect(() => {
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(async ({coords: position}) => {
                if (!position.latitude || !position.longitude || typeof geocodeByLatLng !== 'function') return;
                const coordinates = `${position.latitude},${position.longitude}`;

                geocodeByLatLng({lat: position.latitude, lng: position.longitude})
                  .then(results => {
                    setFieldValue('location', results[0]?.formatted_address || coordinates);
                  }).catch(() => setFieldValue('location', coordinates));
              });
            }
          }, []);

          return (
            <Form>
              <FormWrapper className="bg-white rounded-lg shadow-md">
                <FieldWrapper>
                  <TextField name="name"/>
                </FieldWrapper>

                <FieldWrapper>
                  <TextField name="location"/>
                </FieldWrapper>

                <FieldWrapper className="mt-6 grid grid-cols-2 gap-4">
                  <div/>
                  <PrimaryButton type="submit" className="w-full" disabled={Object.values(errors).length}>
                    Submit <Icon path={mdiChevronRight} size={1}/>
                  </PrimaryButton>
                </FieldWrapper>
              </FormWrapper>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddJob;
