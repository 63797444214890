import { Transition } from '@headlessui/react';
import { mdiCameraFlip, mdiClose, mdiFlash, mdiFlashOff } from '@mdi/js';
import Icon from '@mdi/react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { useEffect, useState } from 'react';
import theme from 'tailwind-theme';

const BackgroundOverlay = ({ show, ...props }) => (
  <Transition.Child
    as="div"
    {...props}
    enter="ease-in-out duration-200"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in-out duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
  />
);

const PanelWrapper = ({ ...props }) => <div className="absolute inset-y-0 left-0 flex w-full" {...props} />;

const Panel = ({ show, ...props }) => (
  <Transition.Child
    as="div"
    {...props}
    enter="transform transition ease-in-out duration-300"
    enterFrom="translate-y-full"
    enterTo="translate-y-0"
    leave="transform transition ease-in-out duration-300"
    leaveFrom="translate-y-0"
    leaveTo="translate-y-full"
    className="relative w-screen"
  />
);

const CloseButton = ({ onClick }) => (
  <div className="absolute right-0 top-0 flex pl-4 pr-2 pt-4">
    <button type="button" aria-label="Close menu" className="text-gray-300 transition duration-150 ease-in-out hover:text-white" onClick={onClick}>
      <Icon path={mdiClose} size={theme.fontSize['2xl'][0]} />
    </button>
  </div>
);

/**
 * @param {boolean} isOpen
 * @param {function} useDefaultCamera
 * @param {function(boolean|function(boolean): boolean)} setOpen
 * @returns {JSX.Element}
 * @constructor
 */
const CameraModal = ({ isOpen, setOpen, useDefaultCamera, storePhoto }) => {
  const closeNav = () => setOpen(false);
  const [imageCapture, setImageCapture] = useState(null);
  const [zoom, setZoom] = useState(localStorage.getItem('zoom') ? localStorage.getItem('zoom') : 1);
  const [flashOn, setFlashOn] = useState(!!localStorage.getItem('flash'));
  const [failedToAccessCamera, setFailedToAccessCamera] = useState(false);
  const [frontFacing, setFrontFacing] = useState(!!localStorage.getItem('facing'));
  const [canFaceFront, setCanFaceFront] = useState(null);

  useEffect(() => {
    imageCapture?.track?.stop();
    if (canFaceFront === null) {
      navigator.mediaDevices.enumerateDevices().then(async (data) => {
        let cameras = data.filter((device) => device.kind === 'videoinput');
        let newCanFaceFront = false;
        cameras.forEach((device) => {
          if (device.label.includes('front')) {
            newCanFaceFront = true;
          }
        });
        setCanFaceFront(newCanFaceFront);
      });
    }
    if (isOpen && canFaceFront !== null) {
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: frontFacing && canFaceFront ? 'user' : 'environment' } })
        .then(async (mediaStream) => {
          document.querySelector('video').srcObject = mediaStream;
          const track = mediaStream.getVideoTracks()[0];
          let newImageCapture = new ImageCapture(track);
          let canUseZoom = 'zoom' in newImageCapture.track.getCapabilities();
          if (canUseZoom) {
            await newImageCapture.track.applyConstraints({ advanced: [{ zoom: zoom }] });
          }
          setImageCapture(newImageCapture);
        })
        .catch((e) => setFailedToAccessCamera(true));
    }
  }, [isOpen, frontFacing, canFaceFront]);

  const onTakePhotoButtonPress = async () => {
    if (imageCapture && !(imageCapture.track.readyState != 'live' || !imageCapture.track.enabled || imageCapture.track.muted)) {
      let canUseTorch = 'torch' in imageCapture.track.getCapabilities();
      if (canUseTorch) {
        await imageCapture.track.applyConstraints({ advanced: [{ torch: flashOn }] });
      }

      const { imageWidth } = await imageCapture.getPhotoCapabilities();

      if (imageWidth.min < 480) {
        imageWidth.min = 480;
      }

      await imageCapture
        .takePhoto({ imageWidth: imageWidth.min.toString() })
        .then((blob) => {
          storePhoto(blob);
          setOpen(false);
        })
        .then(async () => {
          if (canUseTorch) {
            await imageCapture.track.applyConstraints({ advanced: [{ torch: false }] });
          }
        })
        .catch((e) => {
          //ignored. error that occurs only on front camera photo
        });
    }
  };

  const handleSliderZoomChange = async (event) => {
    setZoom(event.target.value);
    let canUseZoom = 'zoom' in imageCapture.track.getCapabilities();
    if (canUseZoom) {
      await imageCapture.track.applyConstraints({ advanced: [{ zoom: event.target.value }] });
    }
    localStorage.setItem('zoom', event.target.value);
  };

  const flashButtonClicked = () => {
    let newFlashValue = !flashOn;
    setFlashOn(newFlashValue);
    newFlashValue ? localStorage.setItem('flash', '1') : localStorage.removeItem('flash');
  };

  const flipButtonClicked = () => {
    let newFrontFacing = !frontFacing;
    setFrontFacing(newFrontFacing);
    newFrontFacing ? localStorage.setItem('facing', '1') : localStorage.removeItem('facing');
  };

  const FlashButton = () => (
    <div className="absolute left-0 top-0 flex pl-3 pt-4">
      {flashOn ? (
        <button aria-label="Flash On" className="text-gray-300" onClick={flashButtonClicked}>
          <Icon path={mdiFlash} size={theme.fontSize['4xl'][0]} />
        </button>
      ) : (
        <button aria-label="Flash Off" className="text-gray-300" onClick={flashButtonClicked}>
          <Icon path={mdiFlashOff} size={theme.fontSize['4xl'][0]} />
        </button>
      )}
    </div>
  );

  const FlipCameraButton = () => (
    <div className="absolute bottom-0 left-0 flex pb-5 pl-3">
      <button aria-label="Flash On" className="text-gray-300" onClick={flipButtonClicked}>
        <Icon path={mdiCameraFlip} size={theme.fontSize['4xl'][0]} />
      </button>
    </div>
  );

  return (
    <Transition show={isOpen} className="fixed inset-0 z-10 overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <BackgroundOverlay show={isOpen} onClick={closeNav} />

        <PanelWrapper>
          <Panel show={isOpen}>
            <div className="relative flex h-full flex-col space-y-6 overflow-y-auto bg-gray-800 shadow-xl">
              <video autoPlay style={{ width: '100vw', height: '100vh', background: 'black' }} />
              <CloseButton onClick={closeNav} />
              <FlashButton />
              <input
                style={{ WebkitAppearance: 'slider-vertical' }}
                className="absolute left-0 top-24 -ml-10 flex"
                onChange={handleSliderZoomChange}
                type="range"
                step="0.1"
                min="1"
                max="7.5"
                value={zoom}
              />
              {failedToAccessCamera ? (
                <div className="flex flex-col justify-items-center">
                  <div className="bg-red-500 p-2 text-center text-white">
                    <p>
                      You have denied permission for {window.clientName} to access your camera. Please tap the button below to open your default camera
                      .
                    </p>
                  </div>
                  <PrimaryButton as="div" onClick={useDefaultCamera}>
                    Use Default Camera
                  </PrimaryButton>
                </div>
              ) : (
                <div>
                  {canFaceFront && <FlipCameraButton />}
                  <button
                    type="button"
                    onClick={onTakePhotoButtonPress}
                    className="absolute bottom-10 left-1/2 -ml-10"
                    style={{
                      borderRadius: '50%',
                      height: '75px',
                      width: '75px',
                      backgroundColor: 'white',
                      border: '5px solid rgba(0,0,0, 0.2)',
                      boxShadow: '  0 0px 5.3px rgba(0, 0, 0, 0.404), 0 0px 17.9px rgba(0, 0, 0, 0.596), 0 0px 80px rgba(0, 0, 0, 1)',
                    }}
                  />
                </div>
              )}
            </div>
          </Panel>
        </PanelWrapper>
      </div>
    </Transition>
  );
};

export default CameraModal;
