import {mdiSync} from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import cls from 'classnames';
import {format} from 'date-fns';
import React, {useState} from 'react';
import {syncAllData} from 'Services/syncAllData';
import valueStore from 'Services/valueStore';

const SyncAllData = ({loading}) => {
  const [syncing, setSyncing] = useState(false);
  const [lastSyncTime, setLastSyncTime] = useState(null);
  const online = useOnlineStatus();
  const syncClasses = async () => {
    const dateNumber = await valueStore.get(`last-sync`)
    setLastSyncTime(dateNumber);
    const lastSync = new Date(dateNumber)
    let minutesToRemove=30;
    let currentDate = new Date();
    let recommendedSyncTime = new Date(currentDate.getTime() - minutesToRemove*60000);
    setSyncedRecently(recommendedSyncTime < lastSync && !isNaN(dateNumber));
  }

  const [syncedRecently, setSyncedRecently] = useState(syncClasses);

  const sync  = async () => {
    setSyncing(true);
    await syncAllData(false)
    await syncClasses()
    setSyncing(false);
  }

  return !loading ? (
<div className="ml-auto mr-auto flex flex-col items-center">
  {
    online ? (
      <>
        <button onClick={sync} className={cls('flex inset-0 justify-center items-center relative rounded-full w-14 p-1 ml-auto mr-auto', syncedRecently ?  'bg-green-500' : 'bg-red-500')}>
          <Icon path={mdiSync} size={2} className={cls('text-white', syncing ?  'animate-reverse-spin' : '')} />
        </button>
        <p className={syncedRecently  ? 'text-green-500' : 'text-red-500'}>{syncedRecently  ? 'Synced' : 'Please Sync your data'}</p>
        {
          !isNaN(lastSyncTime) && <p className={syncedRecently  ? 'text-green-500' : 'text-red-500'}>Last sync:  {format(new Date(lastSyncTime), 'yyyy/MM/dd hh:mm aaa')}</p>
        }
        </>
    ) : <p className="text-red-500">Please go online to sync data</p>
  }

</div>

  ) : null;
};

export default SyncAllData;
