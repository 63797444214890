import {mdiAlert, mdiLoading} from '@mdi/js';
import Icon from '@mdi/react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import PopUp from 'Components/PopUp';
import React, {useState} from 'react';


export const AreYouSureYouDiscardDataPopUp = ({isOpen, setOpen}) => {
  const [disabled, setDisabled] = useState(false);
  return (
    <PopUp setOpen={setOpen} isOpen={isOpen} canClose={false} disabled={disabled}>
      <div className="flex ml-3 mt-4">
        <Icon path={mdiAlert} size={1} className="text-red-500"/>
        <h4 className="px-3 pb-5 text-gray-900 tracking-wider font-semibold w-full" id="projects-headline">
          Warning - Are you sure you want to discard data?
        </h4>
      </div>
      <hr/>
      <div className="px-3 h-full flex flex-col justify-between">
        <div className="pb-4 flex flex-col divide-y divide-gray-200 pt-3">
          <p>You will lose all unsynced data on this device.</p>
        </div>
        <div className="flex space-x-5 mb-4 justify-between">
          <SecondaryButton type="button" disabled={disabled} onClick={async () => {
            setOpen(false, null);
          }}>
            Cancel
          </SecondaryButton>
          <PrimaryButton type="button" disabled={disabled} onClick={() => {
            setDisabled(true);
            setOpen(false, true);
          }} className="py-2 h-full">
            {disabled && <Icon path={mdiLoading} size={1} className="animate-spin text-white mr-2"/>}
            Yes, Discard data
          </PrimaryButton>
        </div>
      </div>
    </PopUp>
  );
};
