import {mdiCalendar, mdiPlus, mdiSync} from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import * as Sentry from '@sentry/react';
import cls from 'classnames';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import ListItem from 'Components/ListItem';
import ListItemWrapper from 'Components/ListItemWrapper';
import SyncSpinner from 'Components/SyncSpinner';
import WarningOfDataLimit from 'Components/WarningOfDataLimit';
import {format, parseJSON} from 'date-fns';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';

const Reports = () => {
  usePageTitle('Forms');

  const isOnline = useOnlineStatus();
  const [reports, setReports] = useState([]);
  const [searchedReports, setSearchedReportsDefault] = useState([]);
  const [syncing, setSyncing] = useState(false);
  const [search, setSearch] = useState('');

  const setSearchedReports = value => {
    if (!Array.isArray(value)) {
      Sentry.addBreadcrumb({
        level: 'debug',
        category: 'searchedReports',
        data: {searchedReports: value},
      });
      throw new Error(`searchedReports is a ${typeof value}`);
    }

    setSearchedReportsDefault(value);
  };

  const sync = async () => {
    if (isOnline && !syncing) {
      setSyncing(true);

      Sentry.addBreadcrumb({
        category: 'searchedReports',
        level: 'debug',
        message: 'Loading reports from api',
      });
      const reportsQueried = await api.syncReports();
      setReports(reportsQueried);
      setSearchedReports(reportsQueried);
    } else {
      Sentry.addBreadcrumb({
        category: 'searchedReports',
        level: 'debug',
        message: 'Loading reports from valueStore',
      });
      const reportsQueried = await valueStore.getArray('reports');
      setReports(reportsQueried);
      setSearchedReports(reportsQueried);
    }

    setSyncing(false);
  };

  useEffect(() => {
    if (search) {
      Sentry.addBreadcrumb({
        category: 'searchedReports',
        level: 'debug',
        message: 'Loading reports from reports state filtering',
      });
      setSearchedReports(reports.filter(report =>
        report.name?.toLowerCase().includes(search.toLowerCase()),
      ));
    } else {
      Sentry.addBreadcrumb({
        category: 'searchedReports',
        level: 'debug',
        message: 'Loading reports from reports state parameter',
      });
      setSearchedReports(reports);
    }
  }, [search]);

  useEffect(() => {
    sync();
  }, []);

  return (
    <div className="m-4">
      <SyncSpinner loading={syncing}/>

      <PrimaryButton as={Link} to={route('reports.select-form-template')} className="w-full mb-12">
        <Icon path={mdiPlus} size={1} className="mr-1"/> Add Form
      </PrimaryButton>

      <ListItemWrapper searchName="Forms" setSearch={setSearch} searchDisabled={syncing}>
        {searchedReports?.map((report, index) => (
          <ListItem
            key={index}
            mainTitle={(
              <div className="flex gap-2 items-center">
                {(report.new || report.updated) && (
                  <div className={cls(report.updated ? 'bg-orange-500' : 'bg-red-500', 'p-1 rounded-2xl')}>
                    <Icon path={mdiSync} size={.7} className="text-white"/>
                  </div>
                )}
                {report.name}
              </div>
            )}
            subTitles={[(
              <>
                <div className="mb-3">
                  <p className="text-gray-900 text-sm"><span className="text-gray-500 font-semibold">Job: </span>{report?.job?.name}</p>
                  {
                    report.description && (<p className="text-gray-900 text-sm"><span
                      className="text-gray-500 font-semibold">Description: </span>{report.description}</p>)
                  }
                </div>
                <div className="text-gray-600 flex space-x-1 text-xs">
                  <Icon path={mdiCalendar} size={0.60}/>
                  <p>{report.created_at === 'now' || !report.created_at ? format(Date.now(), 'yyyy/MM/dd hh:mm aaa') : format(parseJSON(report.created_at), 'yyyy/MM/dd hh:mm aaa')}</p>
                </div>
              </>
            )]}
            as={Link}
            to={report.id || report.temp_id ? route('reports.edit', {id: report.id || report.temp_id}) : '#'}
            status={report.id ? 'New' : 'To be synced'}
          />
        ))}
      </ListItemWrapper>
      <WarningOfDataLimit/>
    </div>
  );
};

export default Reports;
