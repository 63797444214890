import React from 'react';
import auth from 'Services/auth';
import route from 'Support/route';

const Logout = async () => {
  await auth.logout();
  window.location.href = route('login');
  return null;
};

export default Logout;