import {mdiAlert, mdiLoading} from '@mdi/js';
import Icon from '@mdi/react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import PopUp from 'Components/PopUp';
import React, {useCallback} from 'react';
import {useBeforeUnload} from 'react-use';
import auth from 'Services/auth';
import {syncAllData} from 'Services/syncAllData';
import valueStore from 'Services/valueStore';
import useOrganization from 'Support/hooks/useOrganization';

/**
 * @param {boolean} isOpen
 * @param {function(boolean|function(boolean): boolean)} setOpen
 * @returns {boolean} hasData
 * return true or false, this will determine if we should show the popup
 * @constructor
 */

export const hasDataToSync = async () => {
  return (await Promise.all([
    valueStore.getArray(`jobs`) || [],
    valueStore.getArray(`tasks`) || [],
    valueStore.getArray(`taskAnalysis`) || [],
    valueStore.getArray(`reports`) || [],
  ])).flat().some(item => item?.new || item?.updated);
};

export const syncDataFromOtherOrganization = async () => {
  let fail = await hasDataToSync();
  const currentOrganizationId = await valueStore.get('organization');
  const currentUser = await valueStore.get('user');
  const previousUserId = await valueStore.get('previous_user');
  const previousOrganizationId = await valueStore.get('previous_organization');

  if (previousOrganizationId && previousOrganizationId !== currentOrganizationId && previousUserId && previousUserId === currentUser.id) {
    await auth.setOrganization(previousOrganizationId);
    await syncAllData(false);
    fail = await hasDataToSync();
    await auth.setOrganization(currentOrganizationId);
  } else if((!previousUserId || previousUserId !== currentUser.id) && fail){
    fail = true;
  }else {
    await syncAllData(false);
    fail = await hasDataToSync();
  }

  return fail;
};

export const UnSyncedDataPopUp = ({isOpen, setOpen, setValue, disabled, setDisabled, preventRefresh}) => {
  const {previous_organization_name} = useOrganization();

  useBeforeUnload(preventRefresh, 'You have unsynced data. If you leave this page, your data will be misplaced');

  return (
    <PopUp setOpen={setOpen} isOpen={isOpen} canClose={false} disabled={disabled}>
      <div className="flex ml-3 mt-4">
        <Icon path={mdiAlert} size={1} className="text-red-500"/>
        <h4 className="px-3 pb-5 text-gray-900 tracking-wider font-semibold" id="projects-headline">
          Warning - Unsynced Data
        </h4>
      </div>
      <hr/>
      <div className="px-3 h-full flex flex-col justify-between">
        <div className="pb-4 flex flex-col divide-y divide-gray-200 pt-3">
          <p>You have unsynced data for {previous_organization_name}. What should we do? Upload will switch you to the the
            organization: {previous_organization_name} </p>
        </div>
        <div className="flex space-x-5 mb-4 justify-between">
          <SecondaryButton type="button" disabled={disabled} onClick={() => {
            setDisabled(true);
            setOpen(false);
            setValue(true);
          }} className="border-0">
            <span className="text-red-500 font-bold">Discard</span>
          </SecondaryButton>
          <PrimaryButton type="button" disabled={disabled} onClick={() => {
            setDisabled(true);
            setValue(false);
          }} className="py-2 h-full">
            {disabled && <Icon path={mdiLoading} size={1} className="animate-spin text-white mr-2"/>}
            Upload
          </PrimaryButton>
        </div>
      </div>
    </PopUp>
  );
};
