import {Transition} from '@headlessui/react';
import {mdiClose} from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import cls from 'classnames';
import theme from 'tailwind-theme';

const BackgroundOverlay = ({show, ...props}) => (
  <Transition.Child
    as="div"
    {...props}
    enter="ease-in-out duration-200"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in-out duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
  />
);

const PanelWrapper = ({...props}) => (
  <div className=" mr-auto ml-auto mt-28 inset-y-0 max-w-screen-9/10 w-80 flex" {...props} />
);

const Panel = ({show, ...props}) => (
  <Transition.Child
    as="div"
    {...props}
    enter="transform transition ease-in-out duration-300"
    enterFrom="translate-x-full"
    enterTo="translate-x-0"
    leave="transform transition ease-in-out duration-300"
    leaveFrom="translate-x-0"
    leaveTo="translate-x-full"
    className="relative w-screen max-w-md"
  />
);

const CloseButton = ({onClick}) => (
  <div className="absolute top-0 right-0 pt-4 pr-2 flex -ml-12 pl-4">
    <button aria-label="Close menu" className="text-gray-400 hover:text-white transition ease-in-out duration-150" onClick={onClick}>
      <Icon path={mdiClose} size={theme.fontSize['2xl'][0]}/>
    </button>
  </div>
);



const PopUp = ({isOpen, setOpen, canClose = true, children, disabled, height= 'h-72'}) => {
  const closeNav = () => setOpen(false);

  return (
    <Transition show={isOpen} className="fixed inset-0 overflow-hidden z-10">
      <div className="absolute inset-0 overflow-hidden">
        <BackgroundOverlay show={isOpen} onClick={ () => {
          canClose && !disabled  ? closeNav() : null;
        }}/>

        <PanelWrapper>
          <Panel show={isOpen}>
            {canClose && !disabled ? <CloseButton onClick={closeNav}/> : null}
            <div className={cls('rounded-2xl ml-auto mr-auto flex flex-col bg-white shadow-xl overflow-y-auto', height)}>
              {children}
            </div>

          </Panel>
        </PanelWrapper>
      </div>
    </Transition>
  );
};

export default PopUp;