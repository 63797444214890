import {mdiLoading} from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';

const SyncSpinner = ({loading, children = <></>}) => loading
  ? (
    <div className="flex fixed inset-0 justify-center items-center relative z-50">
      <Icon path={mdiLoading} size={3} className="animate-spin"/>
    </div>
  )
  : children;

export default SyncSpinner;