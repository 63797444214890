import {mdiAccount, mdiCalendar} from '@mdi/js';
import Icon from '@mdi/react';
import {defaultSelectStyles} from 'Components/Forms/ReactSelectField.jsx';
import ListItem from 'Components/ListItem';
import ListItemWrapper from 'Components/ListItemWrapper';
import {format, parseJSON} from 'date-fns';
import {taskStatusOptions} from 'Pages/Tasks/EditTask.jsx';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import route from 'Support/route';

const taskStatusFilterOptions = [
  ...taskStatusOptions,
  {
    value: null,
    label: 'All',
  },
];

const TasksList = ({tasks, className, heading, syncing}) => {
  const [searchedTasks, setSearchedTasks] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(taskStatusFilterOptions.find(option => option.value === 'active'));

  useEffect(() => {
    if (search || selectedStatus.value) {
      setSearchedTasks(tasks.filter(task =>
        (task.name?.toLowerCase().includes(search.toLowerCase()) ||
        task.description?.toLowerCase().includes(search.toLowerCase()) ||
        task.user?.name?.toLowerCase().includes(search.toLowerCase()) ||
        task.user?.email?.toLowerCase().includes(search.toLowerCase())) &&
        (!selectedStatus.value || task.status.value === selectedStatus.value)
      ));
    } else {
      setSearchedTasks(tasks);
    }
  }, [search, selectedStatus]);

  useEffect(() => {
    setSearchedTasks(tasks.filter(task => task.status.value === selectedStatus.value));
  }, [tasks]);

  return (
    <ListItemWrapper className={className} searchName="Tasks" setSearch={setSearch} searchDisabled={syncing}>
      <Select
        options={taskStatusFilterOptions}
        value={selectedStatus}
        styles={defaultSelectStyles}
        onChange={(option) => setSelectedStatus(option)}
      />

      {searchedTasks?.map((task, index) => (
        <ListItem
          key={index}
          mainTitle={task.description}
          subTitles={[(
            <div className="flex justify-between">
              <div className="flex space-x-1">
                <Icon path={mdiAccount} size={0.60}/>
                <p>{task.assignee?.name || 'unassigned'}</p>
              </div>
              <div className="ml-2 text-gray-600 flex space-x-1">
                <Icon path={mdiCalendar} size={0.60}/>
                <p>{task.created_at === 'now' || !task.created_at ? format(Date.now(), 'dd/MM/yy') : format(parseJSON(task.created_at), 'dd/MM/yy')}</p>
              </div>
            </div>
          )]}
          as={Link}
          to={task.id || task.temp_id ? route('tasks.edit', {id: task.id || task.temp_id}) : '#'}
          status={task.status?.label || 'To be Synced'}
        />
      ))}
    </ListItemWrapper>
  );
};


export default TasksList;
