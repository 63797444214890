import {mdiAlert, mdiLoading} from '@mdi/js';
import Icon from '@mdi/react';
import BottomNav from 'Components/BottomNav';
import Header from 'Components/Header';
import Sidenav from 'Components/Sidenav';
import SwitchOrganizationNav from 'Components/SwitchOrganizationNav';
import React, {useEffect, useRef, useState, useMemo, createContext} from 'react';
import {JobContextProvider} from 'Support/Contexts/JobContext';
import PrimaryButton from '../Components/Buttons/PrimaryButton';
import SecondaryButton from '../Components/Buttons/SecondaryButton';
import PopUp from '../Components/PopUp';

export const ErrorContext = createContext({
  title: '',
  message: ''
});

const MainLayout = ({children}) => {
  const [navOpen, setNavOpen] = useState(false);
  const [orgSwitchOpen, setOrgSwitchOpen] = useState(false);
  const bottomNavRef = useRef()
  const [errorTitle,setErrorTitle] = useState('')
  const [errorMessage,setErrorMessage] = useState('')
  const [errorOpen,setErrorOpen] = useState(false)

  useEffect(() => {
    if(bottomNavRef.current) {
      bottomNavRef.current.style.bottom = "0";
      let previousScrollPos
      const handleScroll = event => {
        let currentScrollPos = window.scrollY
        if(bottomNavRef.current){
          if ((previousScrollPos > currentScrollPos || !previousScrollPos)) {
            bottomNavRef.current.style.bottom = "0";
          } else {
            bottomNavRef.current.style.bottom = "-100px";
          }
        }

        previousScrollPos = currentScrollPos;
      };
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const errorContextFields = useMemo(()=> ({
    title:(title) => setErrorTitle(title),
    message:(message) => setErrorMessage(message),
    setOpen: (open) => setErrorOpen(open)
  }),[errorOpen])

  return (
    <JobContextProvider>
      <ErrorContext.Provider value={errorContextFields}>
        <PopUp setOpen={setErrorOpen} isOpen={errorOpen} canClose={false}>
          <div className="flex ml-3 mt-4">
            <Icon path={mdiAlert} size={1} className="text-red-500"/>
            <h4 className="px-3 pb-5 text-gray-900 tracking-wider font-semibold w-full" id="projects-headline">
              {errorTitle}
            </h4>
          </div>
          <hr/>
          <div className="px-3 h-full flex flex-col justify-between">
            <div className="pb-4 flex flex-col divide-y divide-gray-200 pt-3">
              <p>{errorMessage}</p>
            </div>
            <div className="flex space-x-5 mb-4 justify-between">
              <PrimaryButton type="button" onClick={() => {
                // setDisabled(true);
                // setOpen(false, true);
              }} className="py-2 h-full">
                {/*{disabled && <Icon path={mdiLoading} size={1} className="animate-spin text-white mr-2"/>}*/}
                OK
              </PrimaryButton>
            </div>
          </div>
        </PopUp>
        <main className="min-h-screen pb-20 bg-gray-100" onScroll={() => console.log('scroll')} >
          <Header isNavOpen={navOpen} setNavOpen={setNavOpen} setSwitchOrgOpen={setOrgSwitchOpen}/>
          <Sidenav isOpen={navOpen} setOpen={setNavOpen}/>
          <SwitchOrganizationNav isOpen={orgSwitchOpen} setOpen={setOrgSwitchOpen}/>
          <div>
            {children}
          </div>
          <div ref={bottomNavRef} className='fixed block w-full duration-300'>
            <BottomNav/>
          </div>
        </main>
      </ErrorContext.Provider>
    </JobContextProvider>
  );
};

export default MainLayout;
