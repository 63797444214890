import {mdiCalendar, mdiContentCopy, mdiPlus, mdiSync} from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import cls from 'classnames';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import ListItem from 'Components/ListItem';
import ListItemWrapper from 'Components/ListItemWrapper';
import SyncSpinner from 'Components/SyncSpinner';
import WarningOfDataLimit from 'Components/WarningOfDataLimit';
import {format, parseJSON} from 'date-fns';
import Tabs from 'Pages/Jobs/Tabs';
import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import {JobContext} from 'Support/Contexts/JobContext';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';
import {v4 as uuidv4} from 'uuid';

const Job = () => {
  const {id} = useParams();
  const {currentJob, updateCurrentJobId, refresh} = useContext(JobContext);
  usePageTitle(currentJob?.name);

  useEffect(() => {
    updateCurrentJobId(id);
    refresh()
  }, [id]);

  const isOnline = useOnlineStatus();
  const [syncing, setSyncing] = useState(false);
  const [taskAnalysis, setTaskAnalysis] = useState([]);
  const [searchedTAs, setSearchedTAs] = useState([]);
  const [search, setSearch] = useState('');

  const history = useHistory();

  const sync = async () => {
    if (isOnline && !syncing) {
      setSyncing(true);
      await api.syncJobs();
      const taskAnalysisQuery = await api.syncTaskAnalysis();
      setTaskAnalysis(taskAnalysisQuery);
      setSearchedTAs(taskAnalysisQuery);
    } else if (!taskAnalysis.length) {
      let taskAnalysisQuery = await valueStore.getArray(`taskAnalysis`) || [];
      let newTaQuery = taskAnalysisQuery.filter(ta => !ta.incomplete);

      setTaskAnalysis(newTaQuery);
      setSearchedTAs(newTaQuery);
    }

    setSyncing(false);
  };

  useEffect(() => {
    if (search) {
      setSearchedTAs(taskAnalysis.filter(ta =>
        ta.activity?.toLowerCase().includes(search.toLowerCase()) ||
        ta.location?.toLowerCase().includes(search.toLowerCase())));
    } else {
      setSearchedTAs(taskAnalysis);
    }
  }, [search]);

  useEffect(async () => {
    await sync();
  }, []);

  const copy = async index => {
    const ta = taskAnalysis?.find(ta => {
      if (ta.id) {
        return ta.id === index;
      }
      return ta.temp_id === index;
    });
    const newTa = {
      temp_id: uuidv4(),
      activity: ta.activity,
      created_at: 'now',
      emergency_location: ta.emergency_location,
      equipment: (ta.equipment || []).map(item => ({...item})),
      training: (ta.training || []).map(item => ({...item})),
      hazards: (ta.hazards || []).map(item => ({...item})),
      job_id: ta.job_id,
      location: ta.location,
      ppe: (ta.ppe || []).map(item => ({...item})),
      required_training: ta.required_training,
      site_good: ta.site_good,
      steps: (ta.steps || []).map(item => ({...item})),
      checks: [],
      incomplete: true,
      new: true,
    };

    await valueStore.set(`taskAnalysis`, [...taskAnalysis, newTa]);

    history.push(route('job.task-analysis.edit', {id: newTa.temp_id}));
  };

  console.log(currentJob)
  return (
    <>
      <Tabs job={currentJob} active="ta"/>
      <div className="p-4 bg-white relative">
        <SyncSpinner loading={syncing}/>

        <PrimaryButton as={Link} to={route('job.task-analysis.add', {id: id})} className="w-full mb-12">
          <Icon path={mdiPlus} size={1} className="mr-1"/> Add Task Analysis
        </PrimaryButton>

        <ListItemWrapper searchName="Task Analyses" setSearch={setSearch} searchDisabled={syncing} className="absolute w-full pb-20">
          {searchedTAs?.map((ta, index) => parseInt(ta.job_id) === parseInt(id)
            ? (
              <ListItem
                key={index}
                as={Link}
                to={ta.id || ta.temp_id ? route('job.task-analysis.view', {id: ta.id || ta.temp_id}) : '#'}
                mainTitle={(
                  <div className="flex gap-2 items-center">
                    {(ta.new || ta.updated) && (
                      <div className={cls(ta.updated ? 'bg-orange-500' : 'bg-red-500', 'p-1 rounded-2xl')}>
                        <Icon path={mdiSync} size={.7} className="text-white"/>
                      </div>
                    )}
                    {ta.activity}
                  </div>
                )}
                subTitles={[
                  (
                    <div className="flex space-x-1">
                      <Icon path={mdiCalendar} size={0.60}/>
                      <p>
                        {ta.created_at === 'now'
                          ? format(Date.now(), 'yyyy/MM/dd hh:mm aaa')
                          : format(parseJSON(ta.created_at), 'yyyy/MM/dd hh:mm aaa')
                        }
                      </p>
                    </div>
                  ),
                ]}
                action={
                  !ta.new &&
                  <SecondaryButton className="ml-auto flex justify-between border shadow border-gray-100 py-1"
                                   onClick={() => copy(ta.id || ta.temp_id)}>
                    <Icon path={mdiContentCopy} className="text-primary" size={.7}/> <p className="text-blue-logo">Copy</p>
                  </SecondaryButton>
                }
              />
            )
            : null,
          )}
        </ListItemWrapper>

        <WarningOfDataLimit/>
      </div>
    </>
  );
};

export default Job;
