import {Offline as OfflineIntegration} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import Initialising from 'Components/Initialising';
import 'Style/app.css';
import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {LayoutContextProvider} from 'Support/Contexts/LayoutContext';
import useUser from 'Support/hooks/useUser';
import App from './interface/App';
import auth from './services/auth';

const RootComponent = () => {
  const [initialised, setInitialised] = useState(false);
  const currentUser = useUser();
  useEffect(() => {
    Promise.all([auth.init()]).then(() => setInitialised(true));
  }, []);
  Sentry.setUser({
    id: currentUser?.id,
    email: currentUser?.email,
  });
  return (
    <React.StrictMode>
      <LayoutContextProvider>{initialised ? <App/> : <Initialising/>}</LayoutContextProvider>
    </React.StrictMode>
  );
};

Sentry.init({
  dsn: window.sentryDsn,
  environment: window.environment,
  integrations: [
    new BrowserTracing(),
    new OfflineIntegration({
      maxStoredEvents: 100,
    }),
  ],
  tracesSampleRate: window.sentryTracesSampleRate,
});
Sentry.setTag('Version', window.version);
ReactDOM.render(<RootComponent/>, document.querySelector('#root'));