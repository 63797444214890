import {mdiAccountSwitch} from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import React, {useContext, useEffect, useState} from 'react';
import {Link, useRouteMatch} from 'react-router-dom';
import api from 'Services/api';
import {LayoutContext} from 'Support/Contexts/LayoutContext';
import usePermissions from 'Support/hooks/usePermissions';
import route from 'Support/route';

const NavToggleButton = ({setNavOpen}) => {
    const toggleNav = () => setNavOpen((navCurrentlyOpen) => !navCurrentlyOpen);

    return (
        <button
            className="px-4 text-white focus:outline-none focus:bg-gray-100 focus:text-gray-600 h-full mr-auto"
            onClick={toggleNav}
        >
            <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7"/>
            </svg>
        </button>
    );
};

const ToggleSwitchUser = ({setOpen}) => {
    const toggleNav = () => setOpen((navCurrentlyOpen) => !navCurrentlyOpen);

    return (
        <button
            className="px-4 text-white focus:outline-none focus:bg-gray-100 focus:text-gray-600 h-full mr-auto"
            onClick={toggleNav}
        >
            <Icon path={mdiAccountSwitch} size={1} className="mr-1"/>
        </button>
    );
};

/**
 * @param {boolean} isNavOpen
 * @param {function(boolean|function(boolean): boolean)} setNavOpen
 * @returns {JSX.Element}
 * @constructor
 */
const Header = ({setNavOpen, setSwitchOrgOpen, hideNav = false}) => {
    const {pageTitle} = useContext(LayoutContext);
    const permissions = usePermissions();
    let {isExact} = useRouteMatch({path: '/'});
    const isOnline = useOnlineStatus();
    const isPWAApp = window.matchMedia('(display-mode: standalone)').matches;
    const [organizations, setOrganizations] = useState([]);
    const [syncing, setSyncing] = useState(false);

    const sync = async () => {
        if (!syncing) {
            setSyncing(true);
            setOrganizations(await api.syncOrganizations());
            setSyncing(false);
        }
    };

    useEffect(async () => {
        if (isOnline) {
            await sync();
        }
    }, []);


    return (
        <header>
            {!isOnline && (
                <div className="bg-red-500 text-white text-center p-2">
                    <p>You are currently offline. Some services may be unavailable</p>
                </div>
            )}
            {!isPWAApp && (
                <div className="bg-red-500 text-white text-center p-2">
                    <span>Please install the Mobile App by clicking <Link to={route('install-pwa')}
                                                                          className="text-primary underline">here</Link></span>
                </div>
            )}
            <div className="flex items-center h-16 bg-primary shadow flex">
                {hideNav || <NavToggleButton setNavOpen={setNavOpen}/>}
                {isExact
                    ? (
                        <div className="grow w-full">
                            <img src="/icon/logo-light.png/1" alt="logo" className="max-h-12 ml-auto mr-auto"/>
                        </div>
                    )
                    : (
                        <div className="text-center w-full tracking-wide grow text-white text-lg mr-auto ml-auto">
                            {pageTitle}
                        </div>
                    )
                }

                {
                    organizations?.length > 1 && permissions.show_organization_switch ? <ToggleSwitchUser setOpen={setSwitchOrgOpen}/> :
                        <div className={'w-20'}/>
                }
            </div>
        </header>
    );
};

export default Header;
