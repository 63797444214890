import {
  mdiAccountHardHat,
  mdiClipboardListOutline,
  mdiCog,
  mdiFileFindOutline,
  mdiFileOutline,
  mdiFolderOpen,
  mdiFormTextbox,
  mdiHelpCircle,
  mdiHome,
} from '@mdi/js';
import AuthLayout from 'Layouts/AuthLayout';
import MainLayout from 'Layouts/MainLayout';
import ForgotPassword from 'Pages/Auth/ForgotPassword';
import Login from 'Pages/Auth/Login';
import Logout from 'Pages/Auth/Logout';
import ResetCode from 'Pages/Auth/ResetCode';
import ResetPassword from 'Pages/Auth/ResetPassword';
import Dashboard from 'Pages/Dashboard';
import FileBrowser from 'Pages/FileBrowser';
import InstallPWA from 'Pages/InstallPWA';
import Job from 'Pages/Job';
import JobReports from 'Pages/JobReports';
import Jobs from 'Pages/Jobs';
import AddJob from 'Pages/Jobs/AddJob';
import AddTaskAnalysis from 'Pages/Jobs/AddTaskAnalysis';
import EditTaskAnalysis from 'Pages/Jobs/EditTaskAnalysis';
import ViewTaskAnalysis from 'Pages/Jobs/ViewTaskAnalysis';
import Reports from 'Pages/Reports';
import CreateReport from 'Pages/Reports/CreateReport';
import EditReport from 'Pages/Reports/EditReport';
import SelectFormTemplate from 'Pages/Reports/SelectFormTemplate.jsx';
import Settings from 'Pages/Settings';
import SSSP from 'Pages/SSSP';
import Tasks from 'Pages/Tasks';
import AddTask from 'Pages/Tasks/AddTask';
import Comments from 'Pages/Tasks/Comments';
import EditRecurringTask from 'Pages/Tasks/EditRecurringTask';
import EditTask from 'Pages/Tasks/EditTask';
import VisitorLogs from 'Pages/VisitorLogs';
import VisitorLog from 'Pages/VisitorLogs/VisitorLog';

export default [
  {
    name: 'dashboard',
    path: '/',
    component: Dashboard,
    layout: MainLayout,
    menuTitle: 'Dashboard',
    menuIcon: mdiHome,
    exact: true,
    auth: true,
  },
  {
    name: 'jobs',
    path: '/jobs',
    component: Jobs,
    layout: MainLayout,
    menuTitle: 'Jobs',
    menuIcon: mdiFolderOpen,
    auth: true,
    exact: true,
  },
  {
    name: 'visitor-logs',
    path: '/job/:id/visitor-logs',
    component: VisitorLogs,
    layout: MainLayout,
  },
  {
    name: 'visitor-log.view',
    path: '/job/:jobId/visitor-log/:id',
    component: VisitorLog,
    layout: MainLayout,
  },
  {
    name: 'jobs.add',
    path: '/jobs/add',
    component: AddJob,
    layout: MainLayout,
    auth: true,
    exact: true,
    menuTitle: 'Quick Job',
    menuIcon: mdiAccountHardHat,
  },
  {
    name: 'job.task-analysis.add',
    path: '/job/task-analysis/add/:id?',
    component: AddTaskAnalysis,
    layout: MainLayout,
    menuTitle: 'New Task Analysis',
    menuPath: '/job/task-analysis/add',
    menuIcon: mdiFileFindOutline,
  },
  {
    name: 'job.task-analysis.edit',
    path: '/job/task-analysis/edit/:id',
    component: EditTaskAnalysis,
    layout: MainLayout,
    menuIcon: mdiFolderOpen,
  },
  {
    name: 'job.task-analysis.view',
    path: '/job/task-analysis/view/:id',
    component: ViewTaskAnalysis,
    layout: MainLayout,
    menuIcon: mdiFolderOpen,
  },
  {
    name: 'job.sssp',
    path: '/job/:id/sssp',
    component: SSSP,
    layout: MainLayout,
    menuIcon: mdiFolderOpen,
  },
  {
    name: 'job.reports',
    path: '/job/:id/reports',
    component: JobReports,
    layout: MainLayout,
  },
  {
    name: 'job',
    path: '/job/:id',
    component: Job,
    layout: MainLayout,
    menuIcon: mdiFolderOpen,
  },
  {
    name: 'tasks',
    path: '/tasks',
    component: Tasks,
    layout: MainLayout,
    menuTitle: 'Tasks',
    menuIcon: mdiClipboardListOutline,
    auth: true,
    exact: true,
  },
  {
    name: 'tasks.add',
    path: '/tasks/add',
    component: AddTask,
    layout: MainLayout,
    exact: true,
  },
  {
    name: 'tasks.edit',
    path: '/tasks/edit/:id',
    component: EditTask,
    layout: MainLayout,
    menuIcon: mdiFolderOpen,
  },
  {
    name: 'tasks.recurring.edit',
    path: '/tasks/recurring/edit/:id',
    component: EditRecurringTask,
    layout: MainLayout,
  },
  {
    name: 'tasks.comments',
    path: '/tasks/comments/:id',
    component: Comments,
    layout: MainLayout,
  },
  {
    name: 'reports.create',
    path: '/reports/create/:id',
    component: CreateReport,
    layout: MainLayout,
    menuIcon: mdiFolderOpen,
  },
  {
    name: 'reports.select-form-template',
    path: '/reports/select-form-template',
    component: SelectFormTemplate,
    layout: MainLayout,
    menuIcon: mdiFolderOpen,
  },
  {
    name: 'reports.edit',
    path: '/reports/edit/:id',
    component: EditReport,
    layout: MainLayout,
    menuIcon: mdiFolderOpen,
  },
  {
    name: 'reports',
    path: '/reports',
    component: Reports,
    layout: MainLayout,
    menuTitle: 'Forms',
    menuIcon: mdiFormTextbox,
    auth: true,
    removeForSuperAdmin: true,
  },
  {
    name: 'report.comments',
    path: '/reports/comments:id',
    component: Reports,
    layout: MainLayout,
    menuIcon: mdiFormTextbox,
    auth: true,
  },
  {
    name: 'file.browser',
    path: '/file-browser',
    component: FileBrowser,
    layout: MainLayout,
    menuTitle: 'File Browser',
    menuIcon: mdiFileOutline,
    auth: true,
  },
  {
    name: 'settings',
    path: '/settings',
    component: Settings,
    layout: MainLayout,
    menuTitle: 'Settings',
    menuIcon: mdiCog,
    auth: true,
  },
  {
    name: 'login',
    path: '/auth/login',
    component: Login,
    layout: AuthLayout,
    auth: false,
  },
  {
    name: 'logout',
    path: '/auth/logout',
    component: Logout,
    layout: AuthLayout,
    auth: false,
  },
  {
    name: 'forgot.password',
    path: '/auth/forgot-password',
    component: ForgotPassword,
    layout: AuthLayout,
    auth: false,
  },
  {
    name: 'reset.code',
    path: '/auth/reset-code',
    component: ResetCode,
    layout: AuthLayout,
    auth: false,
  },
  {
    name: 'reset.password',
    path: '/auth/reset-password',
    component: ResetPassword,
    layout: AuthLayout,
    auth: false,
  },
  {
    name: 'install-pwa',
    path: '/install-pwa',
    component: InstallPWA,
    layout: MainLayout,
    menuTitle: 'Install Mobile App',
    menuIcon: mdiHelpCircle,
    exact: true,
    auth: true,
    bottom: true,
  },
];
