import cls from 'classnames';
import OptionalLabel from 'Components/Forms/OptionalLabel';
import {ErrorMessage, Field} from 'formik';
import React, {useMemo} from 'react';

const CheckboxGroupField = (
  {
    name,
    label = null,
    options = [],
    getOptionLabel = option => option.label,
    getOptionValue = option => option.value,
    className = '',
    ...props
  },
) => {
  const mappedOptions = useMemo(() => {
    if (Array.isArray(options)) {
      return options.map(option => ({value: getOptionValue(option), label: getOptionLabel(option), description: option.description}));
    }

    return Object.keys(options).map(value => ({value, label: options[value]}));
  }, [options]);

  return (
    <div>
      <OptionalLabel name={name} label={label}/>

      <div className="mt-1 space-y-5">
        {mappedOptions.map(({value, label, description}) => (
          <div key={value}>
            <div className="relative flex items-start">
              <div className="absolute flex items-center h-5">
                <Field
                  value={String(value)} className={cls('form-checkbox', className)} type="checkbox" name={name}
                  id={`${name}_${value}`} {...props}
                />
              </div>
              <div className="pl-7 text-sm">
                <label className="font-medium text-gray-900" htmlFor={`${name}_${value}`}>{label}</label>
                {description && <div className="text-gray-750">{description}</div>}
              </div>
            </div>
          </div>
        ))}
        <ErrorMessage name={name} component="div" className="text-red-500 text-xs italic font-content"/>
      </div>
    </div>
  );
};

export default CheckboxGroupField;