import useOnlineStatus from '@rehooks/online-status';
import cls from 'classnames';
import {defaultSelectStyles} from 'Components/Forms/ReactSelectField';
import {defaultInputStyles} from 'Components/Forms/TextField';
import {Field} from 'formik';
import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import api from 'Services/api';
import valueStore from 'Services/valueStore';

const ActionField = ({actions, index, setFieldValue}) => {
  const [users, setUsers] = useState([]);
  const isOnline = useOnlineStatus();

  useEffect(async () => {
    if(isOnline){
      setUsers((await api.syncUsers()).map(option => ({
        value: option.id,
        label: option.name,
      })));
    }else{
      setUsers((await valueStore.getArray(`users`) || []).map(option => ({
        value: option.id,
        label: option.name,
      })));
    }
  }, []);

  const onChangeUser = ({value}) => {
    actions[index] = {
      ...actions[index],
      assigned_id: value,
    };

    setFieldValue('actions', actions);
  };

  const onChangeMessage = e => {
    actions[index] = {
      ...actions[index],
      message: e.target.value,
    };

    setFieldValue('actions', actions);
  };

  const onChangeDueIn = e => {
    actions[index] = {
      ...actions[index],
      due_in: parseInt(e.target.value),
    };

    setFieldValue('actions', actions);
  };

  return (
    <div className="space-y-2">
      <Select
        instanceId={`react-select-assigned_id-user-id-${index}`}
        name={`assigned_id.${index}`}
        id={`assigned_id.${index}`}
        onChange={onChangeUser}
        options={users}
        placeholder="Assign task to"
        styles={defaultSelectStyles}
        value={users?.find(option => option.value === actions[index]?.assigned_id)}
      />

      <textarea
        className={cls(defaultInputStyles, 'h-48 block')}
        name={`message.${index}`}
        defaultValue={actions[index]?.message || ''}
        onChange={onChangeMessage}
        placeholder="Task message"
        required={actions[index]?.assigned_id}
      />

      <Field
        className={cls(defaultInputStyles, 'border')}
        name={`due_in.${index}`}
        onChange={onChangeDueIn}
        placeholder="Due in (days)"
        type="number"
        max='1095'
        value={actions[index]?.due_in || ''}
      />
    </div>
  );
};

export default ActionField;
