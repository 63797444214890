import useOnlineStatus from '@rehooks/online-status';
import ListItem from 'Components/ListItem';
import ListItemWrapper from 'Components/ListItemWrapper';
import SyncSpinner from 'Components/SyncSpinner';
import Breadcrumbs from 'Pages/FileBrowser/BreadCrumbs';
import React, {useEffect, useState} from 'react';
import api from 'Services/api';
import usePageTitle from 'Support/hooks/usePageTitle';

const FileBrowser = () => {
    usePageTitle('File Browser');

    const isOnline = useOnlineStatus();
    const [data, setData] = useState([]);
    const [path, setPath] = useState('');
    const [syncing, setSyncing] = useState(false);

    useEffect(async () => {
        if (isOnline && !syncing) {
            setSyncing(true);

            setData(await api.syncFileBrowser(path));
        }

        setSyncing(false);
    }, [path]);

    const fetchFile = (e,path) => {
        e.preventDefault();
        const newTab = window.open();
        api.getFile(path).then((res) => {
            newTab.location.href = res;
        });

    };

    return (
        <div className="m-4">
            <SyncSpinner loading={syncing}/>

            <Breadcrumbs path={path} setPath={setPath}/>

            {isOnline
                ? (
                    <ListItemWrapper>
                        {data?.directories?.map((dir, index) => (
                            <ListItem
                                key={index}
                                mainTitle={(
                                    <button className="flex gap-2 items-center">
                                        {dir.name}
                                    </button>
                                )}
                                onClick={() => setPath(dir.path)}
                            />
                        ))}
                        {data?.files?.map((file, index) => (
                                <ListItem
                                    key={index}
                                    mainTitle={(
                                        <button onClick={(e) => fetchFile(e, file.path)} className="flex gap-2 items-center">
                                            {file.name}
                                        </button>
                                    )}
                                />
                            ),
                        )}
                    </ListItemWrapper>
                )
                : <div>Files can only be viewed while online</div>
            }
        </div>
    );
};

export default FileBrowser;
