import cls from 'classnames';
import OptionalLabel from 'Components/Forms/OptionalLabel';
import {Field} from 'formik';
import React from 'react';

const TextareaField = ({name, label = null, type = 'text', hint,...props}) => (
  <div>
    <OptionalLabel name={name} label={label}/>
    <span className='text-xs text-gray-600'>{hint}</span>
    <div className="mt-1">
      <Field className={cls('form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm', props.className)}
             as="textarea" name={name} {...props}/>
    </div>
  </div>
);


export default TextareaField;