import cls from 'classnames';
import React from 'react';

const defaultClasses = 'space-y-4 divide-y divide-gray-100 p-4 -m-4 text-sm';

const FormWrapper = ({className, children}) => {
  const wrapperClasses = cls(
    defaultClasses,
    className,
  );

  return (
    <div className={wrapperClasses}>
      {children}
    </div>
  );
};

export default FormWrapper;