import { mdiArrowLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import cls from 'classnames';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import FormWrapper from 'Components/Forms/FormWrapper';
import SelectField from 'Components/Forms/SelectField';
import SyncSpinner from 'Components/SyncSpinner';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import ActionList from 'Pages/Reports/ActionList';
import FormField from 'Pages/Reports/FormField';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import useOrganization from 'Support/hooks/useOrganization';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

const CreateReport = () => {
  const { id } = useParams();
  const history = useHistory();
  const { organization_id } = useOrganization();

  const isOnline = useOnlineStatus();
  const [forms, setForms] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [reports, setReports] = useState([]);
  const [syncing, setSyncing] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [canUseActions, setCanUseActions] = useState(false);

  const sync = async () => {
    if (!syncing) {
      setSyncing(true);

      setForms(await api.syncForms());
      setJobs(await api.syncJobs());

      setSyncing(false);
    }
  };

  useEffect(async () => {
    setForms((await valueStore.getArray(`forms`)) || []);
    setReports((await valueStore.getArray(`reports`)) || []);
    setJobs((await valueStore.getArray('jobs')) || []);
  }, []);

  usePageTitle(`Create Form`);

  const formikProps = {
    initialValues: {
      form_template_id: null,
      task_job_id: '',
      name: '',
      description: '',
      form: [],
      actions: [],
    },
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter a name for the Form'),
    form: Yup.array(
      Yup.mixed().test(
        'is-valid',
        (value) => value.value.label,
        (value) => {
          if (value.required) {
            if (['text', 'textarea', 'date', 'datetime-local', 'radio', 'select'].includes(value.type)) {
              return (value.value ?? '').length > 0;
            } else if (value.type === 'number') {
              return value.value;
            } else if (value.type === 'checkbox') {
              return value.value.length > 0;
            } else if (value.type === 'images') {
              if (value.files) {
                return Object.keys(value?.files).length > 0;
              } else {
                return false;
              }
            }
          }
          return true;
        },
      ),
    ),
  });

  const onSubmit = useFormikSubmit(async (values) => {
    setSubmitting(true);

    const updatedReports = [
      ...reports,
      {
        name: values.name,
        task_job_id: values.task_job_id,
        form_template_id: values.form_template_id,
        report_status_id: values.report_status_id,
        temp_id: uuidv4(),
        organization_id: organization_id,
        description: values.description,
        form: values.form.map((formRow) => {
          const row = { ...formRow };
          if (row.type === 'checkbox') {
            row.options = row.options.map((option) => ({ ...option, checked: row.value?.includes(option.value) }));
            row.value = null;
          } else if (row.type === 'radio') {
            row.options = row.options.map((option) => ({ ...option, checked: row.value === option.value }));
            row.value = '';
          } else if (row.type === 'ul' || row.type === 'ol') {
            row.options = row.options.filter((option) => option.value);
          }
          return row;
        }),
        actions: values.actions?.filter((action) => action.message.length > 0 && action.assigned_id),
        new: true,
      },
    ];
    setReports(updatedReports);

    await valueStore.set(`reports`, updatedReports);

    history.push(route('reports'));
  });

  useEffect(async () => {
    if (isOnline) {
      await sync();
    }
  }, [JSON.stringify(reports)]);

  return (
    <div className="m-4">
      <SyncSpinner loading={submitting} />

      <SecondaryButton as={Link} to={route('reports.select-form-template')} className="mb-12 w-full py-2">
        <Icon path={mdiArrowLeft} size={1} className="mr-1" /> Cancel
      </SecondaryButton>

      <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values, setFieldValue, errors }) => {
          useEffect(() => {
            if (id) {
              const form = forms?.find((form) => form.id === parseInt(id));
              if (form) {
                setCanUseActions(form.allow_events);
                setFieldValue('name', form.name);
                setFieldValue('form', form.form);
                setFieldValue('form_template_id', form.id);
              }
            }
          }, [JSON.stringify(forms)]);

          return (
            <Form className="-m-4 rounded-md bg-white p-4 shadow-lg">
              <div className={cls('space-y-4')}>
                <FormWrapper>
                  <div className="py-4">
                    <FieldWrapper>
                      <SelectField
                        name="task_job_id"
                        label="Select a Job"
                        className="sm:col-span-2"
                        labelClasses="w-full place-self-center"
                        wrappingClassName="block sm:grid sm:grid-cols-3 sm:gap-10 grow"
                        options={jobs}
                        valueKey="id"
                        labelKey="name"
                      />
                    </FieldWrapper>
                    {values.form.map((field, index) => (
                      <FieldWrapper key={field.field_uuid}>
                        <FormField key={index} index={index} values={values.form} setFieldValue={setFieldValue} />
                      </FieldWrapper>
                    ))}
                    {canUseActions && (
                      <div className="mt-4">
                        <h3 className="my-2 text-lg font-bold tracking-wide text-gray-900">Corrective Actions</h3>
                        <FieldArray name="actions">
                          {({ push }) => <ActionList setFieldValue={setFieldValue} actions={values.actions || []} push={push} />}
                        </FieldArray>
                      </div>
                    )}
                  </div>
                </FormWrapper>

                <ErrorMessage
                  name="form"
                  className="-mt-1 ml-auto rounded bg-red-500 p-2 pt-3 text-xs text-white shadow-sm"
                  component={({ children: errors, ...props }) => (
                    <p {...props}>
                      {(errors || []).filter((n) => n).length === 1
                        ? `Please fill out the ${errors.filter((n) => n).join('')} field`
                        : `Please fill out the ${errors.filter((n) => n).join(', ')} fields`}
                    </p>
                  )}
                />

                <div className="mt-6 grid grid-cols-2 gap-4">
                  <PrimaryButton type="submit" className="w-full" disabled={Object.values(errors).length}>
                    Submit <Icon path={mdiChevronRight} size={1} />
                  </PrimaryButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateReport;
