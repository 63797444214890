import useOnlineStatus from '@rehooks/online-status';
import MultiField from 'Components/Forms/MultiField';
import React, {useEffect, useMemo, useState} from 'react';
import api from 'Services/api';
import valueStore from 'Services/valueStore';

const PPEList = ({ppe, push, required = false}) => {
  const isOnline = useOnlineStatus();
  const [suggestions, setSuggestions] = useState([]);

  const sync = () => {
    const getPPE = async () => await api.fetchPPE();

    getPPE().then(async ppe => {
      const values = ppe.map(ppe => ppe.description);
      setSuggestions(values);
      await valueStore.set(`ppe`, values);
    });
  };

  const filteredSuggestions = useMemo(() =>  suggestions.filter(suggestion => !ppe.map(ppe => ppe.description.toLowerCase()).includes(suggestion.toLowerCase())), [ppe, suggestions]);

  useEffect(async () => {
    if (isOnline) {
      sync();
    } else {
      await valueStore.getArray(`ppe`);
    }
  }, []);

  return (
    <MultiField name="ppe" required={required} values={ppe} push={push} label="What PPE is required?" placeholder="Add new PPE" suggestions={filteredSuggestions}/>
  );
};

export default PPEList;
