import cls from 'classnames';
import {FormikContext} from 'formik';
import FieldSwitcher from 'Pages/Reports/FieldSwitcher';
import React, {useContext, useEffect, useState} from 'react';

const FormField = ({index, values, setFieldValue, induction = false}) => {
  const [field, setField] = useState(values[index]);
  const [requiresLabel, setRequiresLabel] = useState(!['title'].includes(values[index].type));

  const Element = ['images', 'checkbox', 'radio'].includes(field.type) ? 'div' : 'label';
  const formID = useContext(FormikContext).values?.id || useContext(FormikContext).values?.temp_id;

  useEffect(() => {
    setField(values[index]);
    setRequiresLabel(!['title'].includes(values[index].type));
  }, [JSON.stringify(values)]);

  return (
    <Element className={cls('block sm:grid sm:grid-cols-3 sm:gap-10 grow')}>
      {requiresLabel && (
        <div className="text-gray-800 text-sm">
          {!formID && field.label}
          {field.required && <span className={cls('text-red-500', formID && 'hidden')}>*</span>}
          {field.hint && (
            <div className="text-xs pt-2 text-gray-800">
              {field.hint}
            </div>
          )}
        </div>
      )}
      <div className="sm:col-span-2 lg:mt-0 mt-2">
        <div className="relative">
          <FieldSwitcher values={values} index={index} setFieldValue={setFieldValue} induction={induction}/>
        </div>
      </div>
    </Element>
  );
};

export default FormField;
