export function isEmptyValue(value) {
  if (value === undefined) return true;
  if (value === null) return true;
  if (value === '') return true;
  if (Array.isArray(value) && Array.length === 0) return true;

  return false;
}

export function isNonEmptyValue(value) {
  return !isEmptyValue(value);
}

/**
 *
 * @param value
 * @param ifEmptyValue
 * @returns {*}
 */
export function nonEmptyValueOr(value, ifEmptyValue) {
  if(ifEmptyValue === undefined) {
    throw new Error('Missing ifEmptyValue parameter');
  }
  return isEmptyValue(value) ? ifEmptyValue : value;
}

export function reactSelectArrayMap(data, valueProperty = 'id', labelProperty = 'name') {
  return data.map(item => ({value: item[valueProperty], label: item[labelProperty]}));
}

export function reactSelectObjectMap(data) {
  return Object.entries(data).map(([value, label]) => ({value, label}));
}