import cls from 'classnames';
import StatusBadges from 'Components/StatusBadges';
import React from 'react';

const ListItem = ({mainTitle, subTitles = [], status, action = null, as = 'div', ...props}) => {
  const Element = as;

  return (
    <div className="flex justify-between items-center gap-4 py-2 block w-full">
      <Element className={cls('py-2', action ? 'max-w-2/3' : 'w-full')} {...props} >
        <div className="flex justify-between">
          <div className="font-semibold text-primary text-sm grow">{mainTitle}</div>
          {!action && status && (
            <div className="ml-2 text-sm w-full text-right">
              <StatusBadges status={status}/>
            </div>
          )}
        </div>
        {subTitles && (
          <div className="text-xs text-gray-600 mt-2 flex flex-col items-start">
            {subTitles.map((subTitle, index) => <div key={index}>{subTitle}</div>)}
          </div>
        )}
      </Element>
      {action && (
        <div className="flex flex-col space-y-2 text-sm items-end">
          <StatusBadges status={status}/>
          {action}
        </div>
      )}
    </div>
  );
};

export default ListItem;