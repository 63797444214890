import MultiField from 'Components/Forms/MultiField';
import React, {useState} from 'react';

const StepList = ({steps, push}) => {
  const [suggestions, setSuggestions] = useState();

  // useEffect(() => {
  //   axios.get(route('steps.search')).then(response => {
  //     setSuggestions(response.data.map(step => step.description));
  //   });
  // }, []);

  return (
    <MultiField name="steps" values={steps} push={push} label="What steps will be used?" placeholder="Add new Step" suggestions={suggestions}/>
  );
};

export default StepList;