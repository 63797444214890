import useOnlineStatus from '@rehooks/online-status';
import MultiField from 'Components/Forms/MultiField';
import React, {useEffect, useMemo, useState} from 'react';
import api from 'Services/api';
import valueStore from 'Services/valueStore';

const TrainingList = ({training, push, required = false}) => {
  const isOnline = useOnlineStatus();
  const [suggestions, setSuggestions] = useState([]);

  const sync = () => {
    const getTraining = async () => await api.fetchTraining();

    getTraining().then(async training => {
      const values = training.map(training => training.description);
      setSuggestions(values);
      await valueStore.set(`training`, values);
    });
  };

  const filteredSuggestions = useMemo(() =>  suggestions.filter(suggestion => !training.map(training => training.description.toLowerCase()).includes(suggestion.toLowerCase())), [training, suggestions]);

  useEffect(async () => {
    if (isOnline) {
      sync();
    } else {
      await valueStore.getArray(`training`);
    }
  }, []);

  return (
    <MultiField name="training" values={training} push={push} label="What Training is needed?" placeholder="Add new Training" required={required} suggestions={filteredSuggestions}/>
  );
};

export default TrainingList;
