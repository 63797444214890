import {mdiMagnify} from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import {defaultInputStyles} from 'Components/Forms/TextField';
import React from 'react';

const ListItemWrapper = ({children, className,searchName, setSearch, searchDisabled}) => (
  <div className={cls('bg-white -mx-4 p-4 bg-white shadow-lg rounded-md divide-y divide-gray-100', className)}>
    {searchName &&
      <div className='pb-4 border-b-1 border-gray-100 relative'>
        <input
          onChange={(e) => setSearch(e.target.value)}
          className={cls(defaultInputStyles, 'pl-10')}
          placeholder={'Search ' + searchName}
          disabled={searchDisabled}
          type="search"/>
        <Icon path={mdiMagnify} size={1} className="absolute left-3 top-2 text-gray-300"/>
      </div>
    }
    {children}
  </div>
);

export default ListItemWrapper;
