import {mdiAndroid, mdiApple} from '@mdi/js';
import Icon from '@mdi/react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import install from 'Images/help-images/android/click-install-app.png';
import iconScreen from 'Images/help-images/android/icon-screen.png';
import installScreen from 'Images/help-images/android/install-screen.png';
import settings from 'Images/help-images/android/press-settings.png';
import defaultScreen from 'Images/help-images/android/default.png';
import addHome from 'Images/help-images/iphone/add-to-home.png';
import clickAddHome from 'Images/help-images/iphone/click-add-to-home-screen.png';
import share from 'Images/help-images/iphone/press-share.png';
import safari from 'Images/help-images/iphone/safari.png';
import screenLookLike2 from 'Images/help-images/iphone/screen-look-like-this-2.png';
import React from 'react';
import usePageTitle from 'Support/hooks/usePageTitle';

const AndroidSaveAppProcess = () => (
  <>
    <li>
      <p>you will be prompt with an install screen, just press install</p>
      <img src={installScreen} alt="android install" className="w-full"/>
    </li>
    <li>
      <p>After a couple seconds you will be prompt with an Icon screen. just press Add</p>
      <img src={iconScreen} alt="android Icon selection" className="w-full"/>
    </li>
    <li>
      <p>Your app will now be installed to your home screen. Simply open this, sign in, and you are away</p>
    </li>
  </>
);
const InstallPWA = () => {
  usePageTitle('Install Mobile app');

  return (
    <div className="m-4 space-y-4">
      <div className="bg-white -mx-4 p-4 bg-white shadow-lg rounded-md space-y-4">
        <div>
          <h4 className="text-primary font-semibold text-lg">What Device do you use?</h4>
          <div className="justify-around flex mt-4">
            <PrimaryButton as="a" href="#apple">
              <Icon path={mdiApple} size={1} className="mr-1"/>Apple
            </PrimaryButton>
            <PrimaryButton as="a" href="#android">
              <Icon path={mdiAndroid} size={1} className="mr-1"/>Android
            </PrimaryButton>
          </div>
        </div>
        <div id="apple">
          <h4 className="text-primary font-semibold text-lg">Apple</h4>
          <p className="text-gray-500">To install the App on Apple you must click add to home screen</p>

          <ol className="text-gray-500 list-decimal mx-5 space-y-4">
            <li>
              <p>Make sure you are using Safari</p>
              <img src={safari} alt="Safari" className="w-full"/>
            </li>
            <li>
              <p>Make sure you are on the Mobile website</p>
            </li>
            <li>
              <p>Press the share button which should be at the bottom of your screen</p>
              <img src={share} alt="share button location" className="w-full"/>
            </li>
            <li>
              <p>Now swipe upwards so you can see all items and it looks like so</p>
              <img src={screenLookLike2} alt="view of the safari share menu" className="w-full"/>
            </li>
            <li>
              <p>Click add to home screen</p>
              <img src={clickAddHome} alt="close-up of add to homescreen button" className="w-full"/>
            </li>
            <li>
              <p>You will be prompt with a page like this. Press add in the top right</p>
              <img src={addHome} alt="add screen" className="w-full"/>
            </li>
            <li>
              <p>It should now take you home where the new app will be</p>
            </li>
            <li>
              <p>Now just open the application, and you are away!</p>
            </li>
          </ol>

        </div>
        <div id="android">
          <a name="android">
            <h4 className="text-primary font-semibold text-lg">Android</h4>
            <p className="text-gray-500">To install the App on Android you must First open the Mobile app in Chrome.</p>
            <p  className="text-gray-500">Then, click "Add {window.clientName} to Home screen" when you first sign in.
              It should appear at the bottom of your page.</p>
            <img src={defaultScreen} alt="default screen" className="w-full"/>
            <p className='text-red-500 mt-4'>This message didn't appear or you accidentally closed it? No problem, you can still do it <a className='text-primary underline' href='#manual'>manually here</a>, otherwise, continue the step by step guide</p>
            <ol className="text-gray-500 space-y-4 mx-4 list-decimal mt-4">
              <AndroidSaveAppProcess/>
            </ol>
            <a name='manual'>
              <h4 className="text-primary font-semibold text-lg mt-4">Manually add app to home</h4>
              <p className="text-gray-500">If the above does not work, you will have to install it manually. Here's how:</p>
              <ol className="text-gray-500 space-y-4 mx-4 list-decimal mt-4">
                <li>
                  <p>Open Chrome</p>
                </li>
                <li>
                  <p>When you are on the {window.clientName} mobile app, press the settings button</p>
                  <img src={settings} alt="android settings" className="w-full"/>
                </li>
                <li>
                  <p>Search the settings for install app and tap that option</p>
                  <img src={install} alt="android install" className="w-full"/>
                </li>
                <AndroidSaveAppProcess/>
              </ol>
            </a>
          </a>
        </div>
      </div>
    </div>
  );
};

export default InstallPWA;