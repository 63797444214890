import {Transition} from '@headlessui/react';
import {mdiClose, mdiLoading, mdiOfficeBuilding} from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import cls from 'classnames';
import {AreYouSureYouDiscardDataPopUp} from 'Components/AreYouSureYouDiscardDataPopUp';
import {FailedSyncPopUp, removeDataFromLocalStorage} from 'Components/FailedSyncPopUp';
import {hasDataToSync, syncDataFromOtherOrganization, UnSyncedDataPopUp} from 'Components/UnSyncedDataPopUp';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import api from 'Services/api';
import auth from 'Services/auth';
import {syncAllData} from 'Services/syncAllData';
import valueStore from 'Services/valueStore';
import useOrganization from 'Support/hooks/useOrganization';
import usePermissions from 'Support/hooks/usePermissions';
import useRole from 'Support/hooks/useRole';
import theme from 'tailwind-theme';

const BackgroundOverlay = ({show, ...props}) => (
  <Transition.Child
    as="div"
    {...props}
    enter="ease-in-out duration-200"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in-out duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
  />
);

const PanelWrapper = ({...props}) => (
  <div className="absolute inset-y-0 right-0 max-w-screen-9/10 w-72 flex" {...props} />
);

const Panel = ({show, ...props}) => (
  <Transition.Child
    as="div"
    {...props}
    enter="transform transition ease-in-out duration-300"
    enterFrom="translate-x-full"
    enterTo="translate-x-0"
    leave="transform transition ease-in-out duration-300"
    leaveFrom="translate-x-0"
    leaveTo="translate-x-full"
    className="relative w-screen max-w-md"
  />
);

const CloseButton = ({onClick}) => (
  <div className="absolute top-0 left-0 pt-4 pr-2 flex -ml-12 pl-4">
    <button aria-label="Close menu" className="text-gray-300 hover:text-white transition ease-in-out duration-150" onClick={onClick}>
      <Icon path={mdiClose} size={theme.fontSize['2xl'][0]}/>
    </button>
  </div>
);

/**
 * @param {boolean} isOpen
 * @param {function(boolean|function(boolean): boolean)} setOpen
 * @returns {JSX.Element}
 * @constructor
 */
const SwitchOrganizationNav = ({isOpen, setOpen}) => {
    const closeNav = () => setOpen(false);
    const isOnline = useOnlineStatus();
    const history = useHistory();
    const permissions = usePermissions();
    const {organization_id} = useOrganization();
    const [syncing, setSyncing] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    const [syncFailed, setSyncFailed] = useState(false);
    const [preventRefresh, setPreventRefresh] = useState(false);
    const [organizationIdSwitchingTo, setOrganizationIdSwitchingTo] = useState(null);
    const [organizationNameSwitchingTo, setOrganizationNameSwitchingTo] = useState(null);
    const [organizationRoleSwitchingTo, setOrganizationRoleSwitchingTo] = useState(null);

    const [discardData, setDiscardData] = useState(null);
    const [unSyncDataDisabled, setUnSyncDataDisabled] = useState(false);
    const [failedSyncOpen, setFailedSyncOpen] = useState(false);
    const [areYouSureOpen, setAreYouSureOpen] = useState(false);

    const sync = async () => {
      if (!syncing) {
        setSyncing(true);
        setOrganizations(await api.syncOrganizations());
        setSyncing(false);
      }
    };

    const switchOrganizationAuthentication = async (id, name, role) => {
      await Promise.all([
        valueStore.set('previous_organization', auth.getOrganization()),
        valueStore.set('previous_organization_name', auth.getOrganizationName()),
      ]);
      await Promise.all([
        auth.setOrganization(id),
        auth.setOrganizationName(name),
        auth.setRole(role),
      ]);
    }

    //run when we select a value on discard data pop up
    useEffect(async () => {
      if (discardData !== null) {
        if (!discardData) {
          await switchOrganizationAuthentication(organizationIdSwitchingTo, organizationNameSwitchingTo, organizationRoleSwitchingTo);
          if (await syncDataFromOtherOrganization()) {
            setFailedSyncOpen(true);
          } else {
            await afterEverything();
          }
        } else {
          setAreYouSureOpen(true);
        }
      }
    }, [discardData]);

    const switchOrganization = async (id, role, name) => {
      setSyncing(true);
      await auth.setPreviousOrganizationName(auth.getOrganizationName());
      setOrganizationIdSwitchingTo(id);
      setOrganizationNameSwitchingTo(name);
      setOrganizationRoleSwitchingTo(role);
      if (!await hasDataToSync()) {
        await switchOrganizationAuthentication(id, name, role);
        await valueStore.delete(`last-sync`);
        await syncAllData(true);
        history.go(0);
      } else {
        setPreventRefresh(true);
        setSyncFailed(true);
      }
    };

    const afterEverything = async () => {
      setPreventRefresh(false);
      setSyncing(false);
      await valueStore.delete(`last-sync`);
      history.go(0);
    };

    const OrganizationItem = ({organizationID, organizationName, organizationRole, active}) => (
      <button
        className={cls({
          'bg-gray-100 text-gray-700': active,
          'text-gray-700 hover:bg-gray-100': !active,
          'flex w-full px-4 py-4 text-sm leading-5 text-left space-x-3 items-center': true,
        })}
        onClick={(e) => active ? e.preventDefault() : switchOrganization(organizationID, organizationRole, organizationName)}
      >
           <span className="relative inline-block shrink-0">
              <Icon path={mdiOfficeBuilding} size={1} className="h-5 w-5 text-gray-500"/>
              <span className={cls(active ? 'bg-green-400' : 'bg-gray-300',
                'absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-gray-100')}/>
              </span>
        <span className="truncate">{organizationName}</span>
      </button>);


    useEffect(async () => {
      if (isOnline && permissions.show_organization_switch) {
        await sync();
      }
    }, []);

    const endAreYouSure = async (areYouSureOpen, newDiscardData) => {
      setAreYouSureOpen(areYouSureOpen);
      setDiscardData(newDiscardData);
      if (newDiscardData) {
        await removeDataFromLocalStorage();
        await afterEverything();
      } else {
        setSyncFailed(true);
        setUnSyncDataDisabled(false);
      }
    };

    return (
      <Transition show={isOpen} className="fixed inset-0 overflow-hidden z-10">
        <div className="absolute inset-0 overflow-hidden">
          <BackgroundOverlay show={isOpen} onClick={closeNav}/>
          <UnSyncedDataPopUp isOpen={syncFailed} setOpen={setSyncFailed} setValue={setDiscardData} disabled={unSyncDataDisabled}
                             setDisabled={setUnSyncDataDisabled} preventRefresh={preventRefresh}/>
          <FailedSyncPopUp isOpen={failedSyncOpen} setOpen={setFailedSyncOpen} onSubmit={afterEverything}/>
          <AreYouSureYouDiscardDataPopUp isOpen={areYouSureOpen} setOpen={endAreYouSure}/>
          <PanelWrapper>
            <Panel show={isOpen}>
              <CloseButton onClick={closeNav}/>

              <div className="relative h-full flex flex-col bg-white shadow-xl overflow-y-auto">
                <h4 className="px-3 pb-2 text-xs text-gray-900 tracking-wider uppercase mt-4 font-semibold" id="projects-headline">
                  Companies
                </h4>
                <hr/>
                <div className="relative pb-4 flex flex-col divide-y divide-gray-200">
                  {
                    isOnline ? (
                        organizations?.length > 1 && organizations.map(organization => <OrganizationItem
                          key={organization.id}
                          organizationID={organization.id}
                          organizationName={organization.name}
                          organizationRole={organization.pivot?.role?.value}
                          active={organization_id === organization.id}/>)
                      )
                      :
                      <div className="bg-red-500 text-white text-center p-2">
                        <span>Please go online to switch organization</span>
                      </div>

                  }
                </div>
              </div>
              {syncing && <div className="top-0 left-0 absolute h-full w-full bg-white bg-opacity-50 flex flex-wrap justify-center content-center">
                <Icon path={mdiLoading} size={4} className="animate-spin text-gray-700 "/>
              </div>}
            </Panel>
          </PanelWrapper>
        </div>
      </Transition>
    );
  }
;

export default SwitchOrganizationNav;
