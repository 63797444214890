import Icon from '@mdi/react';
import cls from 'classnames';
import React, {forwardRef} from 'react';

const CompactButton = ({children, className = '', startIcon, endIcon, as = 'button', ...otherProps}, ref) => {
  const Element = as;

  if (!className.includes('h-')) {
    className += ' h-6';
  }

  return (
    <Element
      ref={ref}
      type="button"
      className={cls(
        'bg-white inline-flex items-center justify-center text-black transition ease-in-out duration-150 hover:text-primary',
        'text-base px-2 rounded',
        'focus:outline-none hover:opacity-90',
        'disabled:cursor-default disabled:opacity-50',
        className,
      )}
      {...otherProps}>
      {startIcon ? <Icon path={startIcon} size={1}/> : null}
      {children}
      {endIcon ? <Icon path={endIcon} size={1}/> : null}
    </Element>
  );
};

export default forwardRef(CompactButton);
