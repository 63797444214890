
import React from 'react';
import {MagicSpinner} from 'react-spinners-kit';
import theme from 'tailwind-theme';

const Initialising = () => (
  <main className="min-h-screen bg-gray-100 flex flex-col items-center justify-center relative">
    <div className="relative z-10">
      <img src='/icon/logo-dark.png/1' alt="Logo" className="max-w-screen-3/4 mx-auto w-64 mb-3"/>
      <h1 className="text-2xl text-gray-900 mb-16 text-center">We're getting things ready</h1>
    </div>
    <div className="absolute overflow-hidden opacity-25">
      <MagicSpinner size={100} sizeUnit="vh" color={theme.backgroundColor.orange[500]}/>
    </div>
  </main>
);

export default Initialising;