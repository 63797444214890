import {mdiCloudOffOutline} from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import React, {useEffect, useState} from 'react';
import api from 'Services/api';

const ImageDisplay = ({media, uuid,className, induction}) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    api.getImageTemporaryURL(uuid ? uuid : media.uuid, induction).then(setImage);
  }, []);

  return image
    ? <img className={className} src={image} alt={image?.name}/>
    : (
      <div className={cls('bg-white p-4 border border-gray-100 rounded-lg', className)}>
        <Icon path={mdiCloudOffOutline} size={1} className="text-gray-300 m-auto animate-pulse"/>
      </div>
    );
};

export default ImageDisplay;