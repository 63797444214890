import OptionalLabel from 'Components/Forms/OptionalLabel';
import TextField from 'Components/Forms/TextField';
import React, {useEffect} from 'react';
import useDebounce from 'Support/hooks/useDebounce';

const MultiField = ({name, values, push, label, placeholder, required = false, suggestions = null}) => {
  const debouncedValues = useDebounce(values, 100);

  useEffect(() => {
    const hasEmptyItem = debouncedValues.some(value => !value.description?.length);

    if (!hasEmptyItem) {
      push({
        description: '',
      });
    }
  }, [debouncedValues]);

  return (
    <div className="space-y-1">
      {suggestions && (
        <datalist id={`suggestions-${name}`}>
          {suggestions.map((suggestion, index) => <option value={suggestion} key={index}/>)}
        </datalist>
      )}

      <OptionalLabel required={required} name={name} label={label}/>

      {values.map((item, index) => (
        <TextField key={index} label={false} name={`${name}.${index}.description`} placeholder={placeholder} list={`suggestions-${name}`} autoComplete="off"/>
      ))}
    </div>
  );
};

export default MultiField;