import OptionalLabel from 'Components/Forms/OptionalLabel';
import ReactSwitchField from 'Components/Forms/ReactSwitchField';
import {ErrorMessage, Field} from 'formik';
import React from 'react';

const SwitchField = ({name, label = null, type = 'text', ...props}) => (
  <div>
    <OptionalLabel name={name} label={label}/>

    <div className="mt-1">
      <Field component={ReactSwitchField} name={name} {...props}/>
      <ErrorMessage name={name} component="div" className="text-red-500 text-xs italic font-content"/>
    </div>
  </div>
);

export default SwitchField;