import {mdiDownload} from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import Tabs from 'Pages/Jobs/Tabs';
import React, {useContext, useEffect} from 'react';
import {useParams} from 'react-router';
import {JobContext} from 'Support/Contexts/JobContext';
import usePageTitle from 'Support/hooks/usePageTitle';

const SSSP = () => {
  const {id} = useParams();
  const {currentJob, updateCurrentJobId, refresh} = useContext(JobContext);

  useEffect(() => {
    updateCurrentJobId(id);
    refresh()
  }, [id]);

  usePageTitle(currentJob?.name);

  const isOnline = useOnlineStatus();

  return (
    <>
      <Tabs job={currentJob} active="sssp"/>

      <div className="p-4 bg-white rounded-b-lg shadow-md">
        {currentJob?.latest_sssp
          ? (
            <>
              {isOnline
                ? (
                  <PrimaryButton as="a" href={currentJob.latest_sssp} target="_blank" className="w-full mb-12">
                    <Icon path={mdiDownload} size={1} className="mr-1"/> Download SSSP
                  </PrimaryButton>
                )
                : (
                  <>No internet connection, you cannot download the SSSP at the moment</>
                )
              }
            </>
          )
          : (
            <>The SSSP is not available yet</>
          )
        }
      </div>
    </>
  );
};

export default SSSP;