import api from 'Services/api';
import valueStore from 'Services/valueStore';

export const syncAllData = async (jobsMin = true) => {

  jobsMin ?
    await Promise.all([
      api.syncUsers(),
      api.syncJobsMin(),
      api.syncCompanyChecks(),
      api.syncTaskAnalysisTemplates(),
      api.syncForms(),
      api.syncReports(),
      api.syncTasks(),
    ])
    :
    await Promise.all([
      valueStore.set(`last-sync`, Date.now()),
      api.syncUsers(),
      api.syncJobs(),
      api.syncCompanyChecks(),
      api.syncTaskAnalysis(),
      api.syncTaskAnalysisTemplates(),
      api.syncForms(),
      api.syncReports(),
      api.syncTasks(),
    ]);
};