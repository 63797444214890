import useOnlineStatus from '@rehooks/online-status';
import OptionalLabel from 'Components/Forms/OptionalLabel';
import { defaultInputStyles } from 'Components/Forms/TextField.jsx';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useDebounce from 'Support/hooks/useDebounce';
import { Field } from 'formik';
import { useEffect, useMemo, useState } from 'react';

const AttendeesList = ({ attendees, push, required = false }) => {
  const isOnline = useOnlineStatus();
  const [suggestions, setSuggestions] = useState([]);

  const debouncedAttendees = useDebounce(attendees, 100);

  useEffect(() => {
    const hasEmptyItem = debouncedAttendees.some((attendee) => !attendee);

    if (!hasEmptyItem) {
      push('');
    }
  }, [debouncedAttendees]);

  const sync = () => {
    const getAttendees = async () => await api.fetchAttendees();

    getAttendees().then(async (attendeesList) => {
      const values = attendeesList.map((attendee) => attendee.name);
      setSuggestions(values);
      await valueStore.set(`attendees`, values);
    });
  };

  const filteredSuggestions = useMemo(
    () => suggestions.filter((suggestion) => !attendees.map((attendee) => attendee?.toLowerCase()).includes(suggestion?.toLowerCase())),
    [attendees, suggestions],
  );

  useEffect(async () => {
    if (isOnline) {
      sync();
    } else {
      const attendees = await valueStore.getArray(`attendees`);
      setSuggestions(attendees);
    }
  }, []);

  return (
    <div className="space-y-1">
      {filteredSuggestions && (
        <datalist id={`suggestions-attendees`}>
          {filteredSuggestions.map((suggestion, index) => (
            <option value={suggestion} key={index} />
          ))}
        </datalist>
      )}

      <OptionalLabel required={required} name="attendees" label="Attendees" />

      {attendees.map((item, index) => (
        <Field
          className={defaultInputStyles}
          key={index}
          label={false}
          name={`attendees.${index}`}
          placeholder="Add new Attendee"
          list={`suggestions-attendees`}
          autoComplete="off"
        />
      ))}
    </div>
  );
};

/**
 * To finish the below add a button to add a new attendee
 * Button displays if the text is set and modal closed but it is not saved
 */

// const AttendeeSelect = ({ onChange, options: initialOptions, value, className }) => {
//   const [optionsOpen, setOptionsOpen] = useState(false);
//   const [typedAttendee, setTypedAttendee] = useState(value);
//   const options = useMemo(
//     () =>
//       initialOptions.filter(
//         (option) => option.toLowerCase().includes(typedAttendee.toLowerCase()) && !value.toLowerCase().includes(option.toLowerCase()),
//       ),
//     [initialOptions, typedAttendee],
//   );
//   return (
//     <div className={cls('relative')}>
//       <input
//         placeholder="Add new attendee"
//         className={cls(className)}
//         onChange={(e) => setTypedAttendee(e.target.value)}
//         value={typedAttendee}
//         onClick={() => setOptionsOpen(true)}
//         onBlur={() => {
//           setOptionsOpen(false);
//         }}
//       />
//       {typedAttendee !== '' && typedAttendee !== value && !optionsOpen ? <button className="absolute right-0 z-10">asdf</button> : null}
//       <div
//         className={cls(
//           optionsOpen ? 'scale-y-100 ' : 'scale-y-0',
//           'absolute z-50 mt-1 block w-full origin-top divide-y rounded-md border bg-white p-2 shadow transition duration-150 ease-in-out sm:text-sm sm:leading-5',
//         )}
//       >
//         {options.length ? (
//           options.map((option, index) => (
//             <div key={index} onClick={() => onChange(option)} className="py-2">
//               {option}
//             </div>
//           ))
//         ) : (
//           <button
//             onClick={() => {
//               onChange(typedAttendee);
//               setTypedAttendee('');
//             }}
//             className="flex items-center "
//           >
//             <Icon size={1} path={mdiPlus} />
//             Add as new attendee
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

export default AttendeesList;
