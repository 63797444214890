import { animated, useSpring } from '@react-spring/web';
import React from 'react';
import { useMeasure, usePrevious } from 'react-use';
// import styled from 'styled-components';

/**
 * @param {boolean} show
 * @param {string} className
 * @param {React.Children} children
 * @returns {JSX.Element}
 * @constructor
 */
const FieldTransition = ({ show, className = '', children }) => {
  const [ref, { height: viewHeight }] = useMeasure();
  const previouslyOpen = usePrevious(show);

  const { height, opacity, overflow } = useSpring({
    height: show ? viewHeight : 0,
    opacity: show ? 1 : 0,
    overflow: show ? 'initial' : 'hidden',
  });

  return (
    <animated.div style={{ opacity, height: show && previouslyOpen === show ? 'auto' : height, overflow }} className="will-change-[opacity,height]">
      <animated.div className={className} ref={ref} children={children} />
    </animated.div>
  );
};

export default FieldTransition;
