import {mdiAlert} from '@mdi/js';
import Icon from '@mdi/react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import PopUp from 'Components/PopUp';
import React from 'react';

export const PreventSuperAdminSignIn = ({isOpen, setOpen, setValue}) => {

    return (
        <PopUp setOpen={setOpen} isOpen={isOpen} canClose={false}>
            <div className="flex ml-3 mt-4">
                <Icon path={mdiAlert} size={1} className="text-red-500"/>
                <h4 className="px-3 pb-5 text-gray-900 tracking-wider font-semibold" id="projects-headline">
                    Warning - You cannot sign in as a super admin
                </h4>
            </div>
            <hr/>
            <div className="px-3 h-full flex flex-col justify-between">
                <div className="pb-4 flex flex-col divide-y divide-gray-200 pt-3">
                    <p>A Super Admin cannot use the PWA/Mobile app, you must log in as a user with a company</p>
                </div>
                <div className="flex space-x-5 mb-4 justify-between">
                    <PrimaryButton type="button" onClick={() => {
                        setValue(true);
                        setOpen(false)
                    }} className="py-2 h-full">
                        logout
                    </PrimaryButton>
                </div>
            </div>
        </PopUp>
    );
};
