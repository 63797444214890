import {openDB} from 'idb';

/**
 * @param {string} name
 * @param {IdbStoreParameters=} config
 */
export default function createIdbStore(name, config) {
  const dbPromise = openDB(name, 1, {
    upgrade(db) {
      const store = db.createObjectStore(name, config);
      config.afterCreate?.(store);
    },
  });

  return {
    async all() {
      return (await dbPromise).getAll(name);
    },
    async allFromIndex(index) {
      return (await dbPromise).getAllFromIndex(name, index);
    },
    async get(key, throwOnMissing = false) {
      const data = await (await dbPromise).get(name, key);
      if (data === undefined && throwOnMissing) {
        throw new Error(`Unable to locate value for ${key}`);
      }
      return data;
    },
    async getArray(key, throwOnMissing = false){
        const data = await this.get(key, throwOnMissing)
        if(data === undefined || data === null){
            return undefined;
        }
        //idb sometimes gives us an object that looks like an array (no prototype, so no find, filter etc)
        //This ensures it is an array
        return Array.from(data);
    },
    async has(key) {
      return await ((await dbPromise).getKey(name, IDBKeyRange.only(key))) !== undefined;
    },
    async set(key, val) {
      return (await dbPromise).put(name, val, key);
    },
    async delete(key) {
      return (await dbPromise).delete(name, key);
    },
    async clear() {
      return (await dbPromise).clear(name);
    },
    async keys() {
      return (await dbPromise).getAllKeys(name);
    },
  };
}
