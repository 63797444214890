import useOnlineStatus from '@rehooks/online-status';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import OptionalLabel from 'Components/Forms/OptionalLabel';
import {ErrorMessage} from 'formik';
import HazardField from 'Pages/Jobs/HazardField';
import React, {useEffect, useState, useMemo} from 'react';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useDebounce from 'Support/hooks/useDebounce';

const HazardList = ({hazards, push, setFieldValue}) => {
  const isOnline = useOnlineStatus();

  const [suggestions, setSuggestions] = useState([]);
  const [initialSuggestions, setInitialSuggestions] = useState([]);

  useEffect(() => {
    setSuggestions(initialSuggestions?.map(suggestion => ({
      ...suggestion,
      residual_risk_rating: Math.ceil(suggestion.residual_risk_rating / 5) * 5,
    })));
  }, [JSON.stringify(initialSuggestions)]);

  const filteredSuggestions = useMemo(() => suggestions.filter(suggestion => !hazards.map(hazard => hazard.description.toLowerCase()).includes(suggestion.description.toLowerCase())), [hazards, suggestions]);

  const sync = () => {
    const getHazards = async () => await api.fetchHazards();

    getHazards().then(async hazards => {
      setInitialSuggestions(hazards);
      await valueStore.set(`hazards`, hazards);
    });
  };

  useEffect(async () => {
    if (isOnline) {
      sync();
    } else {
      setSuggestions(await valueStore.getArray(`hazards`));
    }
  }, []);

  const debouncedHazards = useDebounce(hazards, 100);

  useEffect(() => {
    const hasEmptyItem = debouncedHazards.some(hazard => !hazard.description?.length);

    if (!hasEmptyItem) {
      push({
        hazard_controls: [],
        description: '',
        initial_risk_rating: '',
        residual_risk_rating: '',
      });
    }
  }, [JSON.stringify(debouncedHazards)]);

  return (
    <div className="border-gray-300 sm:border-gray-200 space-y-2">
      <OptionalLabel name="hazards" className="text-kg font-semibold text-gray-900"/>

      <datalist id="suggestions-hazards">
        {filteredSuggestions?.map((suggestion, index) => <option value={suggestion.description} key={index}/>)}
      </datalist>

      <div className="space-y-4">
        {hazards?.map((hazard, index) => (
          <div key={index}>
            <FieldWrapper className="shadow-md rounded-lg p-4 border border-gray-100">
              <HazardField hazards={hazards} suggestions={filteredSuggestions} index={index} setFieldValue={setFieldValue}/>
            </FieldWrapper>
            {
              hazards.filter(hazard => hazard.description.length > 0).length > 0 &&
              (<ErrorMessage name={`hazards.${index}`} component='div' className='bg-red text-white p-2 text-xs ml-auto -mt-1 pt-3 shadow-sm rounded-md'/>)
            }
          </div>
        ))}
      </div>
    </div>
  );
};

export default HazardList;
