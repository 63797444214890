import cls from 'classnames';
import ReactSelectField from 'Components/Forms/ReactSelectField';
import {ErrorMessage, Field} from 'formik';
import React from 'react';
export const riskRatingValues = [
  {
    value: 5,
    label: 'Very Low',
  },
  {
    value: 10,
    label: 'Low',
  },
  {
    value: 15,
    label: 'Moderate',
  },
  {
    value: 20,
    label: 'High',
  },
  {
    value: 25,
    label: 'Critical',
  },
];

const SelectField = ({name, label = null, className = '', ...props}) => {

  return (
    <>
      <Field
        component={ReactSelectField}
        options={riskRatingValues}
        className={cls('block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 rounded-md shadow-sm', className)}
        name={name} {...props}/>
      <ErrorMessage name={name} component="div" className="text-red-500 text-xs italic font-content"/>
    </>
  );
};

export default SelectField;