import {mdiPlus} from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SyncSpinner from 'Components/SyncSpinner';
import WarningOfDataLimit from 'Components/WarningOfDataLimit';
import TasksList from 'Pages/Tasks/TasksList';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';

const Tasks = () => {
    usePageTitle('Tasks');

    const isOnline = useOnlineStatus();
    const [tasks, setTasks] = useState([]);
    const [syncing, setSyncing] = useState(false);

    const sync = async () => {
        if (isOnline && !syncing) {
            setSyncing(true);

            setTasks(await api.syncTasks());
        } else {
            setTasks(await valueStore.getArray('tasks'));
        }

        setSyncing(false);
    };

    useEffect(async () => {
        await sync();
    }, []);

    return (
        <div className="m-4">
            <SyncSpinner loading={syncing}/>

            <PrimaryButton as={Link} to={route('tasks.add')} className="w-full mb-12">
                <Icon path={mdiPlus} size={1} className="mr-1"/> Add Task
            </PrimaryButton>

            <TasksList tasks={tasks} syncing={syncing}/>
            <WarningOfDataLimit/>
        </div>
    );
};

export default Tasks;
