import useOnlineStatus from '@rehooks/online-status';
import MultiField from 'Components/Forms/MultiField';
import React, {useEffect, useState, useMemo} from 'react';
import api from 'Services/api';
import valueStore from 'Services/valueStore';

const EquipmentList = ({equipment, push, required= false}) => {
  const isOnline = useOnlineStatus();
  const [suggestions, setSuggestions] = useState([]);

  const sync = () => {
    const getEquipment = async () => await api.fetchEquipment();

    getEquipment().then(async equipment => {
      const values = equipment.map(equipment => equipment.description);
      setSuggestions(values);
      await valueStore.set(`equipment`, values);
    });
  };

  const filteredSuggestions = useMemo(() =>  suggestions.filter(suggestion => !equipment.map(equipment => equipment.description.toLowerCase()).includes(suggestion.toLowerCase())), [equipment, suggestions]);

  useEffect(async () => {
    if (isOnline) {
      sync();
    } else {
      await valueStore.getArray(`equipment`);
    }
  }, []);

  return (
    <MultiField name="equipment" values={equipment} required={false} push={push} label="What equipment will be used?" placeholder="Add new Equipment"
                suggestions={filteredSuggestions}/>
  );
};

export default EquipmentList;
