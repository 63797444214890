import cls from 'classnames';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import React from 'react';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import route from 'Support/route';
import * as Yup from 'yup';

const LoginForm = props => {
  const history = useHistory();

  const formikProps = {
    initialValues: {
      code: '',
    },
  };

  const validationSchema = Yup.object().shape({
    code: Yup.number()
      .required('Please provide the reset code that was emailed to you'),
  });

  const onSubmit = useFormikSubmit(async values => {
    const email = await valueStore.get(`reset-email`);
    const verified = await api.checkCode(email, values.code);
    if (verified) {
      await valueStore.set(`reset-code`, values.code);
      history.push(route('reset.password'));
    }
  });

  return (
    <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
      <Form {...props}>
        <div>
          <div className="mb-6">We have sent you an email with a verification code. Copy that code into the field below to verify your account.</div>

          <label htmlFor="code" className="block text-sm font-medium leading-5 text-gray-700">
            Verification code
          </label>
          <div className="mt-1">
            <Field
              className={cls('appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400',
                'focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5')}
              type="number" name="code" placeholder="123456"/>
            <ErrorMessage name="code" component="div" className="text-red-500 text-xs italic font-content"/>
          </div>
        </div>

        <div className="mt-6">
          <span className="block w-full">
            <PrimaryButton type="submit" className="w-full">
              Verify code
            </PrimaryButton>
            <Link to={route('login')} className="text-center text-sm mt-6 block text-primary">Login</Link>
          </span>
        </div>
      </Form>
    </Formik>
  );
};

export default LoginForm;