import React from 'react';

const OptionalLabel = ({required= false, name = null, label = null, className = 'block text-sm font-medium leading-5 text-gray-700'}) => (
  <>
    {label !== false && (
      <label htmlFor={name} className={className}>
        {label ? label : name.charAt(0).toUpperCase() + name.slice(1).replace('_', ' ')} {required ? <span className='font-xs text-red-500 border-0 pt-2'>*</span> : null}
      </label>
    )}
  </>
);

export default OptionalLabel;