import {mdiArrowLeft, mdiChevronRight, mdiDelete} from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import FieldTransition from 'Components/FieldTransition';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import FormWrapper from 'Components/Forms/FormWrapper';
import SelectField from 'Components/Forms/SelectField';
import SwitchField from 'Components/Forms/SwitchField';
import TextField from 'Components/Forms/TextField';
import SyncSpinner from 'Components/SyncSpinner';
import {Form, Formik} from 'formik';
import {entityTypeOptions, recurringOptions} from 'Pages/Tasks/AddTask';
import {taskStatusOptions} from 'Pages/Tasks/EditTask.jsx';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';
import * as Yup from 'yup';

const jobClass = 'App\\Models\\Job';
const reportClass = 'App\\Models\\Report';

const EditRecurringTask = () => {
  const {id} = useParams();
  const history = useHistory();

  const isOnline = useOnlineStatus();
  const [currentRecurringTask, setCurrentRecurringTask] = useState([]);
  const [currentTask, setCurrentTask] = useState();
  const [entityOptions, setEntityOptions] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [reports, setReports] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [syncing, setSyncing] = useState(false);
  const [formikProps, setFormikProps] = useState();
  const [validationSchema, setValidationSchema] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleteWarning, setDeleteWarning] = useState(false);

  const sync = async () => {
    if (!syncing) {
      setSyncing(true);

      setJobs(await api.syncJobs());
      setUsers(await api.syncUsers());
      setReports(await api.syncReports());

      setSyncing(false);
    }
  };

  const deleteRecurringTask = () => {
    if (deleteWarning) {
      setDeleteWarning(false);
      //submit with delete option
      onSubmit({deleted: true}, {});

    } else setDeleteWarning(true);
  };

  useEffect(async () => {
    setJobs(await valueStore.getArray(`jobs`) || []);
    setTasks(await valueStore.getArray(`tasks`) || []);
    setUsers(await valueStore.getArray(`users`) || []);
    setReports(await valueStore.getArray(`reports`) || []);
  }, []);

  usePageTitle(`Edit Recurring Task`);

  const onSubmit = useFormikSubmit(async values => {
    let newRecurringTask = {
      ...currentTask,
      recurring_task: {
        ...values,
        updated: true,
      },
    };

    if (values.deleted === true) {
      newRecurringTask = {
        ...currentTask,
        recurring_task: {
          id: currentRecurringTask.id,
          deleted: true,
        },
      };
    }
    newRecurringTask['status'] = newRecurringTask['status'].value
    let tasksForUpload = tasks.map(task => {
      //temp id is not needed because we do not currently
      // allow to edit this section if it was just created offline
      if (parseInt(id) === task.id) {
        return newRecurringTask;
      }
      return task;
    });

    setTasks(tasksForUpload);
    await valueStore.set(`tasks`, tasksForUpload);

    if(isOnline){
      await api.syncTasks();
    }
    history.push(route('tasks.edit', {id: id}));
  });

  useEffect(async () => {
    let tempCurrentTask = tasks?.find(task => task.id === parseInt(id));
    if (tempCurrentTask) {
      setCurrentTask(tempCurrentTask);
      setCurrentRecurringTask(tasks?.find(task => task.id === parseInt(id)).recurring_task);
    }
  }, [JSON.stringify(tasks), JSON.stringify(currentRecurringTask)]);

  useEffect(async () => {
    if (isOnline) {
      await sync();
    }
  }, [JSON.stringify(tasks)]);

  useEffect(() => {
    if (currentRecurringTask?.id && !formikProps?.initialValues) {
      setFormikProps({
        initialValues: {
          assignee_id: currentRecurringTask.assignee_id || null,
          due_at: currentRecurringTask.due_at?.substring(0, 16) || '',
          name: currentRecurringTask.name || '',
          description: currentRecurringTask.description || '',
          entity_type: currentRecurringTask.entity_type || null,
          entity_id: currentRecurringTask.entity_id || null,
          at_closed: currentRecurringTask.at_closed || false,
          recurring_interval: currentRecurringTask.recurring_interval || 0,
          recurrence_type: currentRecurringTask?.recurrence_type?.value && currentRecurringTask?.recurrence_type?.value !== 'at_closed' ? currentRecurringTask?.recurrence_type?.value : '',
          include_due_at: currentRecurringTask.days_for_next_due_at !== null,
          days_for_next_due_at: currentRecurringTask.days_for_next_due_at || 0,
          status: currentRecurringTask.status?.value || '',
        },
      });
      setValidationSchema(Yup.object().shape({
        name: Yup.string()
          .required('Please enter a name'),

        description: Yup.string()
          .required('Please enter a description'),

        recurring_interval: Yup.mixed().when('at_closed', {
          is: false,
          then: Yup.number().moreThan(0, 'Please set a valid Number of days').required('Please select a number of days before recurring'),
        }),

        recurrence_type: Yup.mixed().when('at_closed', {
          is: false,
          then: () => Yup.string().required('Please select a type of Recurrence'),
        }),

        due_at: Yup.mixed().when('recurrence_type', {
          is: (recurrence_type) => recurrence_type === 'due_at',
          then: Yup.string().required('Please set a Due at date'),
        }),

        days_for_next_due_at: Yup.mixed().when(['at_closed', 'include_due_at'], {
          is: (at_closed, include_due_at) => !at_closed && include_due_at,
          then: Yup.number().moreThan(0, 'Please select a valid number of days').required('please set the amount of days after repeated task creation you want to make the new due at date'),
        }),
      }));
      setLoading(false);
    }
  }, [JSON.stringify(currentRecurringTask)]);

  useEffect(() => {
    if (currentRecurringTask?.entity_type === jobClass) {
      setEntityOptions(jobs);
    }
    if (currentRecurringTask?.entity_type === reportClass) {
      setEntityOptions(reports);
    }
  }, [jobs, reports]);

  return (
    <div className="m-4">
      <SyncSpinner loading={loading}/>

      <SecondaryButton as={Link} to={route('tasks.edit', {id: id})} className="w-full mb-12 py-2">
        <Icon path={mdiArrowLeft} size={1} className="mr-1"/> Back
      </SecondaryButton>

      {!loading && (
        <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({values: {at_closed, due_at, recurrence_type, include_due_at, entity_type}, errors, submitDisabled}) => {
            useEffect(() => {
              if (entity_type === entityTypeOptions[1].value) {
                setEntityOptions(jobs);
              }
              if (entity_type === entityTypeOptions[2].value) {
                setEntityOptions(reports);
              }
            }, [entity_type]);

            return (
              <Form>
                <FormWrapper className="bg-white rounded-lg shadow-md">
                  {users.length > 0 && (
                    <FieldWrapper>
                      <SelectField name="assignee_id" label="Assignee" options={users} valueKey="id" labelKey="name"/>
                    </FieldWrapper>
                  )}

                  <FieldWrapper>
                    <SelectField name="entity_type" label="Link to" options={entityTypeOptions}/>
                    <FieldTransition show={entityOptions.length}>
                      <div className="mt-4">
                        <SelectField name="entity_id" label="Job" options={entityOptions} valueKey="id" labelKey="name"/>
                      </div>
                    </FieldTransition>
                  </FieldWrapper>

                  <FieldWrapper>
                    <TextField name="due_at" type="datetime-local" isRequired={recurrence_type === 'due_at'}/>
                  </FieldWrapper>

                  <FieldWrapper>
                    <TextField name="name" isRequired={true}/>
                  </FieldWrapper>

                  <FieldWrapper>
                    <TextField name="description" isRequired={true}/>
                  </FieldWrapper>

                  <FieldWrapper>
                    <SelectField name="status" options={taskStatusOptions}/>
                  </FieldWrapper>

                  <FieldWrapper>
                    <SwitchField name="at_closed" label="Would you like this task to reoccur on status close?"/>
                  </FieldWrapper>
                  {!at_closed &&
                    <>
                      <p className="flex items-center text-sm text-gray-800">
                        <span className="mr-3">Repeat</span>

                        <FieldWrapper>
                          <TextField name="recurring_interval" type="number" className="w-24"/>
                        </FieldWrapper>
                        <span className="text-red-500 mr-3 ml-1">*</span>

                        <span className="mr-3">days from</span>
                        <FieldWrapper className="w-48">
                          <SelectField
                            name="recurrence_type"
                            options={Object.values(recurringOptions).filter(option => {
                              if (option.value === 'at_closed') {
                                return false;
                              }
                              if (option.value === 'due_at' && !due_at) {
                                return false;
                              }
                              return true;
                            })
                            }
                            valueKey="value" labelKey="label"/>
                        </FieldWrapper>
                        <span className="text-red-500 mr-3 ml-1">*</span>
                      </p>
                      {recurrence_type === 'due_at' &&
                        <>
                          <FieldWrapper>
                            <SwitchField name="include_due_at" label="Would you like Your due date in repeated tasks?"/>
                          </FieldWrapper>
                          {include_due_at &&
                            <FieldWrapper>
                              <TextField name="days_for_next_due_at"
                                         label="Amount of days after repeated task creation you want to make the new due at date" type="number"/>
                            </FieldWrapper>
                          }
                        </>
                      }
                    </>
                  }
                  {
                    deleteWarning &&
                    <p className="text-red-500 pt-6">Are you sure you want to <b>DELETE</b> this Recurring task? Press delete again to accept</p>
                  }
                  <FieldWrapper className="mt-6 grid grid-cols-2 gap-4">
                    <SecondaryButton type="button" className="bg-red-500" onClick={deleteRecurringTask}>
                      <Icon path={mdiDelete} size={1}/>
                    </SecondaryButton>
                    <PrimaryButton type="submit" className="w-full" disabled={Object.values(errors).length}>
                      Submit <Icon path={mdiChevronRight} size={1}/>
                    </PrimaryButton>
                  </FieldWrapper>
                </FormWrapper>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default EditRecurringTask;
