import api from './api';
import valueStore from './valueStore';

class AuthManager {
    constructor() {
        this.attributes = {};
    }

    async init() {
        const permissionHash = await valueStore.get('permissions_hash')
        const user = await valueStore.get('user')
        const token = await valueStore.get('access_token')

        const organizationId = await valueStore.get('organization');
      const organization = ((await valueStore.get('organizations')) || []).find(o => o.id === organizationId);

        await Promise.all([
            this.setToken(token),
            this.setUser(user),
            this.setOrganization(organization?.id),
            this.setOrganizationName(organization?.name),
            this.setOrganizationUsesSSSP(organization?.uses_sssp),
            this.setRole(await valueStore.get('role')),
            this.setPermissions(),
            this.setPermissionsHash(permissionHash),
        ]);

        if(user && token){
            await this.setPermissions(await api.fetchPermissions())
        }
    }

    /**
     * @param {string} email
     * @param {string} password
     * @returns {Promise<boolean>}
     */
    async authenticate({email, password}) {
        try {
            const {user, token, headers} = await api.login({email, password});
            await valueStore.set(`organization`, user.organization_id);
            await valueStore.set(`organization_name`, user.organization?.name);
            await valueStore.set(`role`, user.role?.value);
            await Promise.all([
                this.setToken(token),
                this.setUser(user),
                this.setOrganization(user.organization_id),
                this.setOrganizationName(user.organization?.name),
                this.setRole(user.role?.value),
            ]);
            await this.handlePermissions(headers['x-pwa-permission-hash']);
            return true;
        } catch (error) {
            if (!error.response || error.response.status !== 401) {
                throw error;
            }

            return false;
        }
    }

    /**
     * @param {string} permissionHash
     */
    async handlePermissions(permissionHash) {
        let currentPermissionHash = await valueStore.get('permissions_hash');
        if (!currentPermissionHash) {
            await Promise.all([
                valueStore.set('permissions_hash', permissionHash),
                this.setPermissionsHash(permissionHash),
            ]);
            await this.setPermissions(await api.fetchPermissions());

        }else if (currentPermissionHash !== permissionHash) {
            await this.setPermissions(await api.fetchPermissions());
        }
    }

    async logout() {
        await Promise.all([
            valueStore.delete('access_token'),
            valueStore.delete('user'),
            valueStore.delete('permissions'),
            valueStore.delete('organization'),
            valueStore.set('previous_user', this.attributes['user'].id),
            valueStore.set('previous_organization', this.attributes['organization']),
            valueStore.set('previous_organization_name', this.attributes['organization_name']),
            valueStore.delete('role'),
            valueStore.delete('permissions_hash'),
        ]);
        delete this.attributes['token'];
        delete this.attributes['user'];
        delete this.attributes['organization'];
        delete this.attributes['organization_name'];
        delete this.attributes['role'];
        delete this.attributes['permissions'];
        delete this.attributes['permissions_hash'];
    }

    /**
     * @returns {boolean}
     */
    isAuthenticated() {
        return this.getToken() !== undefined;
    }

    /**
     * @returns {string|undefined}
     */
    getToken() {
        return this.attributes['token'];
    }

    /**
     * @param {string} token
     * @returns {Promise<*>}
     */
    async setToken(token) {
        return _set('token', token, 'access_token');
    }

    /**
     * @returns {User|undefined}
     */
    getUser() {
        return this.attributes['user'];
    }

    getOrganization() {
        return this.attributes['organization'];
    }

    getOrganizationName() {
        return this.attributes['organization_name'];
    }

    getOrganizationUsesSSSP() {
        return this.attributes['organization_uses_sssp'];
    }

    getPreviousOrganizationName() {
        return this.attributes['previous_organization_name'];
    }


    getRole() {
        return this.attributes['role'];
    }

    getPermissions() {
        return this.attributes['permissions'];
    }

    getPermissionsHash() {
        // return this.attributes['permissions_hash'];
    }

    /**
     * @param {User} user
     * @returns {Promise<*>}
     */
    async setUser(user) {
        return _set('user', user);
    }

    async setOrganizationName(organizationName) {
        return _set('organization_name', organizationName);
    }

    async setOrganizationUsesSSSP(usesSSSP) {
        return _set('organization_uses_sssp', usesSSSP);
    }

    async setPreviousOrganizationName(organizationName) {
        return _set('previous_organization_name', organizationName);
    }

    async setOrganization(organization) {
        return _set('organization', organization);
    }

    async setRole(role) {
        return _set('role', role);
    }

    async setPermissions(permissions) {
        return _set('permissions', permissions);
    }

    async setPermissionsHash(permissionsHash) {
        return _set('permissions_hash', permissionsHash);
    }
}

const manager = new AuthManager();

const _set = (async function (key, value, storeKey = key) {
    this.attributes[key] = value;
    return valueStore.set(storeKey, value);
}).bind(manager);

export default manager;
