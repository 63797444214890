import cls from 'classnames';
import React from 'react';

const defaultClasses = 'pt-4';

const FieldWrapper = ({className, children}) => {
  const wrapperClasses = cls(
    defaultClasses,
    className,
  );

  return (
    <div className={wrapperClasses}>
      {children}
    </div>
  );
};

export default FieldWrapper;